import React, { Component, useMemo, useEffect, useState } from 'react';
import { Container, Form, Row, Col, Button, Image } from 'react-bootstrap';
import concernloading from "../../Images/molconcern-loading.png";
import './Maintain.css';

export default class LocalComplaint extends Component {
    render() {
        return (
            <div className="App" >
                <header className="App-header bg">
                    <img src={concernloading} className="App-logo" alt="Loading" />
                    <p className='text-dark'>
                        We are <code>working</code> on this Page.
                    </p>
                    <p className='text-dark'>
                        Please check back soon!
                    </p>
                </header>
            </div>
        );
    }
}