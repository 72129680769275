import React, { Component, useMemo, useState, useEffect } from 'react';
import { Container, Form, Row, Col, Button, Image, InputGroup, Modal } from 'react-bootstrap';
import { storage, submitSeafarerDeathData } from '../../firebase';
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import concernlogo from "../../Images/concernlogo.png";
import TopBar from "../TopBar/TopBar"
import LoadingSceen from '../Loading/Loading';
import { useDropzone } from 'react-dropzone'

//Dropzone styling
const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '26px',
    borderWidth: 2,
    borderRadius: 8,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};

const focusedStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ff1744'
};

const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16
};

const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: 'border-box'
};

const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
};

const img = {
    display: 'block',
    width: 'auto',
    height: '100%'
};

export default class SeafarerDeath extends Component {
    state = {
        "help": localStorage.getItem("help"),
        "loading": true,
        "showModal": false,
        "name": null,
        "email": null,
        "position": null,
        "cdcNo": null,
        "imoNo": null,
        "ship": null,
        "description": null,
        "contact": null,
        "contactNumber": null,
        "nokImg": [],
        "contractImg": [],
        "deadNoteImg": [],
        "notebyCaptainImg": [],
        "timestamp": new Date().toLocaleString('en-US', { timeZone: 'Asia/Yangon' }),
        "emptyField": [],
        "isMobile": window.matchMedia("only screen and (max-width: 760px)").matches
    }
    componentDidMount() {
        setTimeout(() => this.setState({ loading: false }), 2000);
    }
    render() {
        const handleMobile = () => {
            const getMobile = window.matchMedia("only screen and (max-width: 760px)").matches;
            this.setState({ isMobile: getMobile })
        };
        window.addEventListener('resize', handleMobile);

        const setCommunication = (val) => {
            console.log(val)
            this.setState({ contact: val });
        }
        const ImageUpload = (props) => {
            const [files, setFiles] = useState([]);
            const keyImage = props.fileType;
            const {
                getRootProps,
                getInputProps,
                isFocused,
                isDragAccept,
                isDragReject
            } = useDropzone({
                multiple: true,
                accept: { 'image/*': [] },
                onDrop: acceptedFiles => {
                    setFiles(acceptedFiles.map(file => Object.assign(file, {
                        preview: URL.createObjectURL(file)
                    })));
                    acceptedFiles.forEach(file => {
                        const imageRef = ref(storage, `images/${keyImage}/${file.path}`);
                        console.log(imageRef)
                        uploadBytes(imageRef, file).then((snapshot) => {
                            getDownloadURL(snapshot.ref).then((url) => {
                                console.log(url)
                                // this.setState({ nokImg: [...this.state.nokImg, url] }) 
                                if (keyImage === "nok") {
                                    this.setState({ nokImg: [...this.state.nokImg, url] })
                                } else if (keyImage === "contract") {
                                    this.setState({ contractImg: [...this.state.contractImg, url] })
                                } else if (keyImage === "deadNote") {
                                    this.setState({ deadNoteImg: [...this.state.deadNoteImg, url] })
                                } else if (keyImage === "notebyCaptain") {
                                    this.setState({ notebyCaptainImg: [...this.state.notebyCaptainImg, url] })
                                }
                            });
                        });
                    })
                }
            });

            const style = useMemo(() => ({
                ...baseStyle,
                ...(isFocused ? focusedStyle : {}),
                ...(isDragAccept ? acceptStyle : {}),
                ...(isDragReject ? rejectStyle : {})
            }), [
                isFocused,
                isDragAccept,
                isDragReject
            ]);

            useEffect(() => {
                // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
                return () => files.forEach(file => URL.revokeObjectURL(file.preview));
            }, []);

            return (
                <div className="container">
                    <div {...getRootProps({ style })}>
                        <input {...getInputProps()} />
                        {/* <img src='https://cdn-icons-png.flaticon.com/512/72/72808.png' width={50}></img> */}
                        {/* <br></br> */}
                        <p>ဓာတ်ပုံအားထည့်သွင်းပါ</p>
                        <p className='photonotic'>{props.title}</p>
                    </div>
                </div>
            );
        }

        const checkValidation = () => {
            if (this.state.name === null) {
                this.setState(prevState => ({
                    emptyField: [...prevState.emptyField, "name"]
                }))
            } else {
                const errArr = this.state.emptyField.filter((item) => item !== "name");
                this.setState({ emptyField: errArr });
            }

            if (this.state.imoNo === null) {
                this.setState(prevState => ({
                    emptyField: [...prevState.emptyField, "imoNo"]
                }))
            } else {
                const errArr = this.state.emptyField.filter((item) => item !== "imoNo");
                this.setState({ emptyField: errArr });
            }

            if (this.state.nokImg.length === 0) {
                this.setState(prevState => ({
                    emptyField: [...prevState.emptyField, "nokImg"]
                }))
            } else {
                const errArr = this.state.emptyField.filter((item) => item !== "nokImg");
                this.setState({ emptyField: errArr });
            }

            if (this.state.contractImg.length === 0) {
                this.setState(prevState => ({
                    emptyField: [...prevState.emptyField, "contractImg"]
                }))
            } else {
                const errArr = this.state.emptyField.filter((item) => item !== "contractImg");
                this.setState({ emptyField: errArr });
            }

            if (this.state.deadNoteImg.length === 0) {
                this.setState(prevState => ({
                    emptyField: [...prevState.emptyField, "deadNoteImg"]
                }))
            } else {
                const errArr = this.state.emptyField.filter((item) => item !== "deadNoteImg");
                this.setState({ emptyField: errArr });
            }

            if (this.state.notebyCaptainImg.length === 0) {
                this.setState(prevState => ({
                    emptyField: [...prevState.emptyField, "notebyCaptainImg"]
                }))
            } else {
                const errArr = this.state.emptyField.filter((item) => item !== "notebyCaptainImg");
                this.setState({ emptyField: errArr });
            }

            if (this.state.ship === null) {
                this.setState(prevState => ({
                    emptyField: [...prevState.emptyField, "ship"]
                }))
            } else {
                const errArr = this.state.emptyField.filter((item) => item !== "ship");
                this.setState({ emptyField: errArr });
            }

            if (this.state.description === null) {
                this.setState(prevState => ({
                    emptyField: [...prevState.emptyField, "description"]
                }))
            } else {
                const errArr = this.state.emptyField.filter((item) => item !== "description");
                this.setState({ emptyField: errArr });
            }

            if (this.state.contact === null) {
                this.setState(prevState => ({
                    emptyField: [...prevState.emptyField, "contact"]
                }))
            } else {
                const errArr = this.state.emptyField.filter((item) => item !== "contact");
                this.setState({ emptyField: errArr });
            }

            if (this.state.contactNumber === null) {
                this.setState(prevState => ({
                    emptyField: [...prevState.emptyField, "contactNumber"]
                }))
            } else {
                const errArr = this.state.emptyField.filter((item) => item !== "contactNumber");
                this.setState({ emptyField: errArr });
            }

            if (this.state.name != null ||
                this.state.imoNo != null ||
                this.state.nokImg.length != 0 ||
                this.state.contractImg.length != 0 ||
                this.state.deadNoteImg.length != 0 ||
                this.state.notebyCaptainImg.length != 0 ||
                this.state.ship != null ||
                this.state.description != null ||
                this.state.contact != null ||
                this.state.contactNumber != null) {
                this.setState({ showModal: true });
            }
        }

        return (
            <>
                {
                    this.state.loading === false ? (
                        <Container fluid className='bg p-0'>
                            <TopBar />
                            <Header title={"သင်္ဘောသားတိုင်ကြားရေးယန္တရား"} subtitle={"သင်္ဘောသား သေဆုံးမှုများအားတိုင်ကြားနိုင်ရန်"} />
                            <Form className={`container bg-body rounded ${this.state.isMobile === false ? "shadow col-lg-8 my-5 p-5" : "mx-2.5 p-4 mb-4"}`}>
                                <Form.Group className="mb-4 row">
                                    <Col md={12}>
                                        <Form.Label className='helplabel'>လိုအပ်သည့်အကူအညီ - {this.state.help} နှင့်ပတ်သက်သည့် အကူအညီလိုအပ်ပါသည်။</Form.Label>
                                    </Col>
                                </Form.Group>
                                <hr className='scline'></hr>
                                <Form.Group className="mb-4 row">
                                    <Col md={12}>
                                        <Form.Label className='col-md-12'>သေဆုံးသူသင်္ဘောသားအမည် <strong className='mandatorytxt'>*</strong></Form.Label>
                                        <Form.Control isInvalid={this.state.emptyField.includes('name')} type="text" placeholder="သေဆုံးသူ၏အမည်အားထည့်သွင်းပါ" name="name" onChange={e => this.setState({ name: e.target.value })} />
                                        <Form.Control.Feedback type='invalid'>
                                            {'အမည်အားဖြည့်ပါ'}
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group>
                                <Form.Group className="row">
                                    <Col md={6} className="mb-4">
                                        <Form.Label className='col-md-12'>ရာထူး/အလုပ်အကိုင် </Form.Label>
                                        <Form.Control type="text" placeholder="ရာထူး/အလုပ်အကိုင်အားထည့်သွင်းပါ" name="position" onChange={e => this.setState({ position: e.target.value })} />
                                    </Col>
                                    <Col md={6} className="mb-4">
                                        <Form.Label className='mb-2'>သေဆုံးသူ သင်္ဘောသား၏ CDC အမှတ် </Form.Label>
                                        <Form.Control type="text" placeholder="သင်္ဘောသား၏ CDC အမှတ်" name="cdc_number" onChange={e => this.setState({ cdcNo: e.target.value })} />
                                    </Col>
                                </Form.Group>
                                <Form.Group className="mb-4 row">
                                    <Col md={12}>
                                        <Form.Label className='col-md-12'>NOK အထောက်အထား <strong className='mandatorytxt'>*</strong></Form.Label>
                                        <br></br>
                                        <br></br>
                                        <ImageUpload fileType="nok" title="ဓာတ်ပုံပါစာမျက်နှာ" />
                                        <aside style={thumbsContainer}>
                                            {this.state.nokImg.map((file, index) => (
                                                <div style={thumb} key={index}>
                                                    <div style={thumbInner}>
                                                        <Image
                                                            src={file}
                                                            style={img}
                                                            onLoad={() => { URL.revokeObjectURL(file) }}
                                                        />
                                                    </div>
                                                </div>
                                            ))}
                                        </aside>
                                        {this.state.emptyField.includes('nokImg') === true && (
                                            <label className="invalid invalid-feedback">ဓာတ်ပုံပါစာမျက်နှာ အားထည့်သွင်းပါ။</label>
                                        )}
                                    </Col>
                                </Form.Group>
                                <Form.Group className="row">
                                    <Col md={6} className="mb-4">
                                        <Form.Label className='col-md-8'>သင်္ဘော၏ IMO နံပါတ် <strong className='mandatorytxt'>*</strong></Form.Label>
                                        <Form.Control isInvalid={this.state.emptyField.includes('imoNo')} type="text" placeholder="သင်္ဘော အမျိုးအစား အားထည့်သွင်းပါ" name="imo_number" onChange={e => this.setState({ imoNo: e.target.value })} />
                                        <Form.Control.Feedback type='invalid'>
                                            {'နံပါတ်အားဖြည့်ပါ'}
                                        </Form.Control.Feedback>
                                    </Col>
                                    <Col md={6} className="mb-4">
                                        <Form.Label className='col-md-8'>သင်္ဘော အမည် <strong className='mandatorytxt'>*</strong></Form.Label>
                                        <Form.Control isInvalid={this.state.emptyField.includes('ship')} type="text" placeholder="သင်္ဘော အမည်အားထည့်သွင်းပါ" name="ship" onChange={e => this.setState({ ship: e.target.value })} />
                                        <Form.Control.Feedback type='invalid'>
                                            {'အမည်အားဖြည့်ပါ'}
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group>
                                <Form.Group className='mb-4 row'>
                                    <Col md={12}>
                                        <Form.Label className='col-md-12'>အလုပ်ခန့်ထားမှုစာချုပ် <strong className='mandatorytxt'>*</strong></Form.Label>
                                        <br></br>
                                        <br></br>
                                        <ImageUpload fileType="contract" title="အလုပ်ခန့်ထားမှုစာချုပ်ဓာတ်ပုံ" />
                                        <aside style={thumbsContainer}>
                                            {this.state.contractImg.map((file, index) => (
                                                <div style={thumb} key={index}>
                                                    <div style={thumbInner}>
                                                        <Image
                                                            src={file}
                                                            style={img}
                                                            onLoad={() => { URL.revokeObjectURL(file) }}
                                                        />
                                                    </div>
                                                </div>
                                            ))}
                                        </aside>
                                        {this.state.emptyField.includes('contractImg') === true && (
                                            <label className="invalid invalid-feedback">အလုပ်ခန့်ထားမှုစာချုပ်ဓာတ်ပုံ အားထည့်သွင်းပါ။</label>
                                        )}
                                    </Col>
                                </Form.Group>
                                <Form.Group className='mb-4 row'>
                                    <Col md={12}>
                                        <Form.Label className='col-md-12'>သေဆုံးကြောင်းအထောက်အထား <strong className='mandatorytxt'>*</strong></Form.Label>
                                        <br></br>
                                        <br></br>
                                        <ImageUpload fileType="deadNote" title="သေဆုံးကြောင်းအထောက်အထားဓာတ်ပုံ" />
                                        <aside style={thumbsContainer}>
                                            {this.state.deadNoteImg.map((file, index) => (
                                                <div style={thumb} key={index}>
                                                    <div style={thumbInner}>
                                                        <Image
                                                            src={file}
                                                            style={img}
                                                            onLoad={() => { URL.revokeObjectURL(file) }}
                                                        />
                                                    </div>
                                                </div>
                                            ))}
                                        </aside>
                                        {this.state.emptyField.includes('deadNoteImg') === true && (
                                            <label className="invalid invalid-feedback">သေဆုံးကြောင်းအထောက်အထားဓာတ်ပုံ အားထည့်သွင်းပါ။</label>
                                        )}
                                    </Col>
                                </Form.Group>
                                <Form.Group className='mb-4 row'>
                                    <Col md={12}>
                                        <Form.Label className='col-md-12'>Captain / သက်ဆိုင်ရာအာဏာပိုင်၏ သေဆုံးမှုအစီရင်ခံစာ <strong className='mandatorytxt'>*</strong></Form.Label>
                                        <br></br>
                                        <br></br>
                                        <ImageUpload fileType="notebyCaptain" title="သေဆုံးမှုအစီရင်ခံစာ ဓာတ်ပုံ" />
                                        <aside style={thumbsContainer}>
                                            {this.state.notebyCaptainImg.map((file, index) => (
                                                <div style={thumb} key={index}>
                                                    <div style={thumbInner}>
                                                        <Image
                                                            src={file}
                                                            style={img}
                                                            onLoad={() => { URL.revokeObjectURL(file) }}
                                                        />
                                                    </div>
                                                </div>
                                            ))}
                                        </aside>
                                        {this.state.emptyField.includes('notebyCaptainImg') === true && (
                                            <label className="invalid invalid-feedback">Captain / သက်ဆိုင်ရာအာဏာပိုင်၏ သေဆုံးမှုအစီရင်ခံစာ အားထည့်သွင်းပါ။</label>
                                        )}
                                    </Col>
                                </Form.Group>
                                <Form.Group className="mb-4 row">
                                    <Col md={12}>
                                        <Form.Label>သေဆုံးမှုဖြစ်စဉ်အကျဉ်း <strong className='mandatorytxt'>*</strong></Form.Label>
                                        <Form.Control
                                            className="form-control"
                                            name="cmbody"
                                            isInvalid={this.state.emptyField.includes('description')}
                                            id="exampleFormControlTextarea1"
                                            rows="5"
                                            onChange={e => this.setState({ description: e.target.value })}
                                            as="textarea" />
                                        {/* <textarea className="form-control" id="exampleFormControlTextarea1" rows="5" onChange={e => this.setState({ description: e.target.value })}></textarea> */}
                                        <Form.Control.Feedback type='invalid'>
                                            {'ဖြစ်စဉ်အကျဉ်းအားဖြည့်ပါ'}
                                        </Form.Control.Feedback>
                                        <Form.Text className="text-muted">
                                            သေဆုံးသူစတင်တာဝန်ထမ်း‌ဆောင်သည့်နေ့၊ သေဆုံးသည့်နေ့ စသဖြင့်...
                                        </Form.Text>
                                    </Col>
                                </Form.Group>
                                <Form.Group className="row">
                                    <Form.Label className='col-md-12'>ဆက်သွယ်နိုင်မည့်ဖုန်းနံပါတ် <strong className='mandatorytxt'>*</strong>
                                    </Form.Label>
                                    <Col md={4} className='mb-3'>
                                        <Form.Select isInvalid={this.state.emptyField.includes('contact')} aria-label="‌-- ရွေးချယ်ပါ။  --" onChange={(e) => { setCommunication(e.target.value) }}>
                                            <option>‌-- ရွေးချယ်ပါ။  --</option>
                                            <option value="mobile">Mobile</option>
                                            <option value="viber">Viber</option>
                                            <option value="whatapps">WhatsApp</option>
                                            <option value="line">Line</option>
                                            <option value="telegram">Telegram</option>
                                            <option value="signal">Signal</option>
                                            <option value="wechat ">WeChat</option>
                                        </Form.Select>
                                        <Form.Control.Feedback type='invalid'>
                                            {'ရွေးချယ်ပါ'}
                                        </Form.Control.Feedback>
                                    </Col>
                                    <Col md={8} className='mb-4'>
                                        <InputGroup className="mb-3">
                                            <Form.Control isInvalid={this.state.emptyField.includes('contactNumber')} type="text" placeholder="ဖုန်းနံပါတ်အားထည့်သွင်းပါ" name="phoneno" onChange={e => this.setState({ contactNumber: e.target.value })} />
                                            <InputGroup.Text id="basic-addon1" >Tel</InputGroup.Text>
                                            <Form.Control.Feedback type='invalid'>
                                                {'ဖုန်းနံပါတ်အားဖြည့်ပါ'}
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Col>
                                </Form.Group>
                                <Form.Group className='mb-4 row'>
                                    <Col md={12}>
                                        <Form.Label className='col-md-8'>အီးမေးလ် လိပ်စာ <strong className='optionaltxt'>(Optional)</strong></Form.Label>
                                        <InputGroup className="mb-3">
                                            <Form.Control type="email" placeholder="သင်၏အီးမေးလ်လိပ်စာအားထည့်သွင်းပါ" onChange={e => this.setState({ email: e.target.value })} />
                                            <InputGroup.Text id="basic-addon2" name="email">@</InputGroup.Text>
                                        </InputGroup>
                                    </Col>
                                </Form.Group>
                                <Form.Group className={`row ${this.state.isMobile === false ? "mb-4" : "mb-2"}`}>
                                    <Col md={12} className="d-grid gap-2">
                                        <Button variant="primary" className="mt-3 btn-lg" size="lg" onClick={checkValidation}> Submit </Button>
                                        <DataModal show={this.state.showModal} onHide={() => this.setState({ showModal: false })} data={this.state} />
                                    </Col>
                                </Form.Group>
                            </Form>
                            <Footer />
                        </Container>
                    ) : (
                        <LoadingSceen />
                    )
                }
            </>
        )
    }
}


function DataModal(props) {
    const submitData = () => {
        submitSeafarerDeathData(props.data).then((res) => {
            console.log(res)
            if (res.code === 200) {
                localStorage.setItem("code", res.code);
                localStorage.setItem("message", res.message);
            } else {
                localStorage.setItem("code", res.code);
                localStorage.setItem("message", res.message);
            }
            window.location = '/success?seafarerdeath';
        });
    }
    return (
        <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    အချက်အလက်များအားစစ်ဆေးပါ။
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group className="mb-4 row">
                    <Col md={6}>
                        <Form.Label className='mb-2'> <strong> သေဆုံးသူသင်္ဘောသားအမည်  </strong></Form.Label>
                    </Col>
                    <Col md={6}>
                        <Form.Label className='mb-2'> : {props.data.name} </Form.Label>
                    </Col>
                </Form.Group>
                <Form.Group className="mb-4 row">
                    <Col md={6}>
                        <Form.Label className='mb-2'> <strong> ရာထူး/အလုပ်အကိုင် </strong></Form.Label>
                    </Col>
                    <Col md={6}>
                        <Form.Label className='mb-2'> : {props.data.position} </Form.Label>
                    </Col>
                </Form.Group>
                <Form.Group className="mb-4 row">
                    <Col md={6}>
                        <Form.Label className='mb-2'> <strong> သေဆုံးသူ သင်္ဘောသား၏ CDC အမှတ် </strong></Form.Label>
                    </Col>
                    <Col md={6}>
                        <Form.Label className='mb-2'> : {props.data.cdcNo} </Form.Label>
                    </Col>
                </Form.Group>
                <Form.Group className="mb-4 row">
                    <Col md={6}>
                        <Form.Label className='mb-2'> <strong> NOK အထောက်အထား </strong></Form.Label>
                    </Col>
                    <Col md={6}>
                        {props.data.nokImg.map((file, index) => (
                            <div style={thumb} key={index}>
                                <div style={thumbInner}>
                                    <Image
                                        src={file}
                                        style={img}
                                        onLoad={() => { URL.revokeObjectURL(file) }}
                                    />
                                </div>
                            </div>
                        ))}
                    </Col>
                </Form.Group>
                <Form.Group className="mb-4 row">
                    <Col md={6}>
                        <Form.Label className='mb-2'> <strong> သင်္ဘော၏ IMO နံပါတ်</strong></Form.Label>
                    </Col>
                    <Col md={6}>
                        <Form.Label className='mb-2'> : {props.data.imoNo} </Form.Label>
                    </Col>
                </Form.Group>
                <Form.Group className="mb-4 row">
                    <Col md={6}>
                        <Form.Label className='mb-2'> <strong> သင်္ဘော အမည် </strong></Form.Label>
                    </Col>
                    <Col md={6}>
                        <Form.Label className='mb-2'> : {props.data.ship} </Form.Label>
                    </Col>
                </Form.Group>
                <Form.Group className="mb-4 row">
                    <Col md={6}>
                        <Form.Label className='mb-2'> <strong> အလုပ်ခန့်ထားမှုစာချုပ် </strong></Form.Label>
                    </Col>
                    <Col md={6}>
                        {props.data.contractImg.map((file, index) => (
                            <div style={thumb} key={index}>
                                <div style={thumbInner}>
                                    <Image
                                        src={file}
                                        style={img}
                                        onLoad={() => { URL.revokeObjectURL(file) }}
                                    />
                                </div>
                            </div>
                        ))}
                    </Col>
                </Form.Group>
                <Form.Group className="mb-4 row">
                    <Col md={6}>
                        <Form.Label className='mb-2'> <strong> သေဆုံးကြောင်းအထောက်အထား </strong></Form.Label>
                    </Col>
                    <Col md={6}>
                        {props.data.deadNoteImg.map((file, index) => (
                            <div style={thumb} key={index}>
                                <div style={thumbInner}>
                                    <Image
                                        src={file}
                                        style={img}
                                        onLoad={() => { URL.revokeObjectURL(file) }}
                                    />
                                </div>
                            </div>
                        ))}
                    </Col>
                </Form.Group>
                <Form.Group className="mb-4 row">
                    <Col md={6}>
                        <Form.Label className='mb-2'> <strong> Captain / သက်ဆိုင်ရာအာဏာပိုင်၏ သေဆုံးမှုအစီရင်ခံစာ </strong></Form.Label>
                    </Col>
                    <Col md={6}>
                        {props.data.notebyCaptainImg.map((file, index) => (
                            <div style={thumb} key={index}>
                                <div style={thumbInner}>
                                    <Image
                                        src={file}
                                        style={img}
                                        onLoad={() => { URL.revokeObjectURL(file) }}
                                    />
                                </div>
                            </div>
                        ))}
                    </Col>
                </Form.Group>
                <Form.Group className="mb-4 row">
                    <Col md={6}>
                        <Form.Label className='mb-2'> <strong> သေဆုံးမှုဖြစ်စဉ်အကျဉ်း </strong></Form.Label>
                    </Col>
                    <Col md={6}>
                        <Form.Label className='mb-2'> : {props.data.description} </Form.Label>
                    </Col>
                </Form.Group>
                <Form.Group className="mb-4 row">
                    <Col md={6}>
                        <Form.Label className='mb-2'> <strong> ဆက်သွယ်နိုင်မည့်ဖုန်းနံပါတ် </strong></Form.Label>
                    </Col>
                    <Col md={6}>
                        <Form.Label className='mb-2 d-block'> : {props.data.contact} - {props.data.contactNumber} </Form.Label>
                    </Col>
                </Form.Group>
                <Form.Group className="mb-4 row">
                    <Col md={6}>
                        <Form.Label className='mb-2'> <strong> အီးမေးလ် လိပ်စာ </strong></Form.Label>
                    </Col>
                    <Col md={6}>
                        <Form.Label className='mb-2 d-block'> : {props.data.email} </Form.Label>
                    </Col>
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.onHide}>Cancel</Button>
                <Button onClick={submitData}>Confirm</Button>
            </Modal.Footer>
        </Modal>
    );
}