import React, { Component } from 'react';
import { Container, Form, Row, Col, Button, Image, InputGroup, Modal } from 'react-bootstrap';
import { submitSeafarerData } from '../../firebase';
import './SeafarerComplaint.css';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import concernlogo from "../../Images/concernlogo.png";
import TopBar from "../TopBar/TopBar"
import LoadingSceen from '../Loading/Loading';

export default class SeafarerComplaint extends Component {
    state = {
        "loading": true,
        "help": localStorage.getItem("help"),
        "showModal": false,
        "name": null,
        "nationality": null,
        "cdcNo": null,
        "passport": null,
        "company": null,
        "position": null,
        "ship": null,
        "shipType": null,
        "shipFlag": null,
        "imoNo": null,
        "currentLoc": null,
        "nextLoc": null,
        "estTime": null,
        "seamanCount": null,
        "tonCount": null,
        "ownerName": null,
        "dpaName": null,
        "dpaPhone": null,
        "dpaEmail": null,
        "description": null,
        "problemstartDate": null,
        "goBackDate": null,
        "startDate": null,
        "contractEndDate": null,
        "otherDes": null,
        "contact": null,
        "desSame": null,
        "contactNumber": null,
        "email": null,
        "timestamp": new Date().toLocaleString('en-US', { timeZone: 'Asia/Yangon' }),
        "emptyField": [],
        "isMobile": window.matchMedia("only screen and (max-width: 760px)").matches
    }
    componentDidMount() {
        setTimeout(() => this.setState({ loading: false }), 2000);
    }
    render() {

        const handleMobile = () => {
            const getMobile = window.matchMedia("only screen and (max-width: 760px)").matches;
            this.setState({ isMobile: getMobile })
        };
        window.addEventListener('resize', handleMobile);

        const setCommunication = (val) => {
            this.setState({ contact: val });
        }
        const checkValidation = () => {
            if (this.state.name === null) {
                this.setState(prevState => ({
                    emptyField: [...prevState.emptyField, "name"]
                }))
            } else {
                const errArr = this.state.emptyField.filter((item) => item !== "name");
                this.setState({ emptyField: errArr });
            }

            if (this.state.cdcNo === null) {
                this.setState(prevState => ({
                    emptyField: [...prevState.emptyField, "cdcNo"]
                }))
            } else {
                const errArr = this.state.emptyField.filter((item) => item !== "cdcNo");
                this.setState({ emptyField: errArr });
            }
            if (this.state.company === null) {
                this.setState(prevState => ({
                    emptyField: [...prevState.emptyField, "company"]
                }))
            } else {
                const errArr = this.state.emptyField.filter((item) => item !== "company");
                this.setState({ emptyField: errArr });
            }
            if (this.state.position === null) {
                this.setState(prevState => ({
                    emptyField: [...prevState.emptyField, "position"]
                }))
            } else {
                const errArr = this.state.emptyField.filter((item) => item !== "position");
                this.setState({ emptyField: errArr });
            }
            if (this.state.ship === null) {
                this.setState(prevState => ({
                    emptyField: [...prevState.emptyField, "ship"]
                }))
            } else {
                const errArr = this.state.emptyField.filter((item) => item !== "ship");
                this.setState({ emptyField: errArr });
            }
            if (this.state.shipType === null) {
                this.setState(prevState => ({
                    emptyField: [...prevState.emptyField, "shipType"]
                }))
            } else {
                const errArr = this.state.emptyField.filter((item) => item !== "shipType");
                this.setState({ emptyField: errArr });
            }
            if (this.state.shipFlag === null) {
                this.setState(prevState => ({
                    emptyField: [...prevState.emptyField, "shipFlag"]
                }))
            } else {
                const errArr = this.state.emptyField.filter((item) => item !== "shipFlag");
                this.setState({ emptyField: errArr });
            }
            if (this.state.imoNo === null) {
                this.setState(prevState => ({
                    emptyField: [...prevState.emptyField, "imo"]
                }))
            } else {
                const errArr = this.state.emptyField.filter((item) => item !== "imo");
                this.setState({ emptyField: errArr });
            }
            if (this.state.currentLoc === null) {
                this.setState(prevState => ({
                    emptyField: [...prevState.emptyField, "location"]
                }))
            } else {
                const errArr = this.state.emptyField.filter((item) => item !== "location");
                this.setState({ emptyField: errArr });
            }
            if (this.state.nextLoc === null) {
                this.setState(prevState => ({
                    emptyField: [...prevState.emptyField, "nextloc"]
                }))
            } else {
                const errArr = this.state.emptyField.filter((item) => item !== "nextloc");
                this.setState({ emptyField: errArr });
            }
            if (this.state.estTime === null) {
                this.setState(prevState => ({
                    emptyField: [...prevState.emptyField, "estTime"]
                }))
            } else {
                const errArr = this.state.emptyField.filter((item) => item !== "estTime");
                this.setState({ emptyField: errArr });
            }
            if (this.state.dpaName === null) {
                this.setState(prevState => ({
                    emptyField: [...prevState.emptyField, "dpa"]
                }))
            } else {
                const errArr = this.state.emptyField.filter((item) => item !== "dpa");
                this.setState({ emptyField: errArr });
            }
            if (this.state.dpaPhone === null) {
                this.setState(prevState => ({
                    emptyField: [...prevState.emptyField, "dpaPhone"]
                }))
            } else {
                const errArr = this.state.emptyField.filter((item) => item !== "dpaPhone");
                this.setState({ emptyField: errArr });
            }
            if (this.state.dpaEmail === null) {
                this.setState(prevState => ({
                    emptyField: [...prevState.emptyField, "dpaEmail"]
                }))
            } else {
                const errArr = this.state.emptyField.filter((item) => item !== "dpaEmail");
                this.setState({ emptyField: errArr });
            }
            if (this.state.description === null) {
                this.setState(prevState => ({
                    emptyField: [...prevState.emptyField, "description"]
                }))
            } else {
                const errArr = this.state.emptyField.filter((item) => item !== "description");
                this.setState({ emptyField: errArr });
            }
            if (this.state.problemstartDate === null) {
                this.setState(prevState => ({
                    emptyField: [...prevState.emptyField, "problemstart"]
                }))
            } else {
                const errArr = this.state.emptyField.filter((item) => item !== "problemstart");
                this.setState({ emptyField: errArr });
            }
            if (this.state.startDate === null) {
                this.setState(prevState => ({
                    emptyField: [...prevState.emptyField, "start"]
                }))
            } else {
                const errArr = this.state.emptyField.filter((item) => item !== "start");
                this.setState({ emptyField: errArr });
            }
            if (this.state.contractEndDate === null) {
                this.setState(prevState => ({
                    emptyField: [...prevState.emptyField, "end"]
                }))
            } else {
                const errArr = this.state.emptyField.filter((item) => item !== "end");
                this.setState({ emptyField: errArr });
            }
            if (this.state.desSame === null) {
                this.setState(prevState => ({
                    emptyField: [...prevState.emptyField, "desSame"]
                }))
            } else {
                const errArr = this.state.emptyField.filter((item) => item !== "desSame");
                this.setState({ emptyField: errArr });
            }
            if (this.state.contact === null) {
                this.setState(prevState => ({
                    emptyField: [...prevState.emptyField, "contact"]
                }))
            } else {
                const errArr = this.state.emptyField.filter((item) => item !== "contact");
                this.setState({ emptyField: errArr });
            }
            if (this.state.contactNumber === null) {
                this.setState(prevState => ({
                    emptyField: [...prevState.emptyField, "phone"]
                }))
            } else {
                const errArr = this.state.emptyField.filter((item) => item !== "phone");
                this.setState({ emptyField: errArr });
            }
            console.log(this.state.emptyField)
            if (this.state.name != null ||
                this.state.cdcNo != null ||
                this.state.company != null ||
                this.state.position != null ||
                this.state.ship != null ||
                this.state.shipType != null ||
                this.state.shipFlag != null ||
                this.state.imoNo != null ||
                this.state.location != null ||
                this.state.nextLoc != null ||
                this.state.estTime != null ||
                this.state.dpa != null ||
                this.state.dpaPhone != null ||
                this.state.dpaEmail != null ||
                this.state.description != null ||
                this.state.problemstartDate != null ||
                this.state.start != null ||
                this.state.contractEndDate != null ||
                this.state.desSame != null) {
                this.setState({ showModal: true });
            }
        }
        console.log(this.state.emptyField)
        return (
            <>
                {
                    this.state.loading === false ? (
                        <Container fluid className='bg p-0'>
                            <TopBar />
                            <Header title={"သင်္ဘောသားတိုင်ကြားရေးယန္တရား"} subtitle={"သင်္ဘောသားရေးရာကိစ္စရပ်များအားတိုင်ကြားနိုင်ရန်"} />
                            <Form className={`container bg-body rounded ${this.state.isMobile === false ? "shadow col-lg-8 my-5 p-5" : "mx-2.5 p-4 mb-4"}`}>

                                <Form.Group className="mb-4 row">
                                    <Col md={12}>
                                        <Form.Label className='helplabel'>လိုအပ်သည့်အကူအညီ - {this.state.help} နှင့်ပတ်သက်သည့် အကူအညီလိုအပ်ပါသည်။</Form.Label>
                                    </Col>
                                </Form.Group>
                                <hr className='scline'></hr>
                                <Form.Group className="row">
                                    <Col md={6} className="mb-4">
                                        <Form.Label className='col-md-6'>အမည် <strong className='mandatorytxt'>*</strong></Form.Label>
                                        <Form.Control isInvalid={this.state.emptyField.includes('name')} type="text" placeholder="သင်၏အမည်အားထည့်သွင်းပါ" name="name" onChange={e => this.setState({ name: e.target.value })} />
                                        <Form.Control.Feedback type='invalid'>
                                            {'အမည်အားဖြည့်ပါ'}
                                        </Form.Control.Feedback>
                                    </Col>
                                    <Col md={6} className="mb-4">
                                        <Form.Label className='col-md-6'>နိုင်ငံသား <strong className='optionaltxt'>(Optional)</strong></Form.Label>
                                        <Form.Control type="text" placeholder="နိုင်ငံသားအားထည့်သွင်းပါ" name="nationality" onChange={e => this.setState({ nationality: e.target.value })} />
                                    </Col>
                                </Form.Group>
                                <Form.Group className="row">
                                    <Col md={6} className="mb-4">
                                        <Form.Label className='mb-2'>Passport နံပါတ် <strong className='optionaltxt'>(Optional)</strong></Form.Label>
                                        <Form.Control type="text" name="passport" placeholder="Passport နံပါတ်အားထည့်သွင်းပါ" onChange={e => this.setState({ passport: e.target.value })} />
                                    </Col>
                                    <Col md={6} className="mb-4">
                                        <Form.Label className='mb-2'>CDC အမှတ် <strong className='mandatorytxt'>*</strong></Form.Label>
                                        <Form.Control isInvalid={this.state.emptyField.includes('cdcNo')} type="text" name="cdcNo" placeholder="CDC အမှတ်အားထည့်သွင်းပါ" onChange={e => this.setState({ cdcNo: e.target.value })} />
                                        <Form.Control.Feedback type='invalid'>
                                            {'CDC အမှတ်အားဖြည့်ပါ'}
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group>
                                <Form.Group className="row">
                                    <Col md={6} className="mb-4">
                                        <Form.Label className='col-md-12'>မြန်မာနိုင်ငံမှအလုပ်ခန့်သည့်ကုမ္ပဏီ <strong className='mandatorytxt'>*</strong></Form.Label>
                                        <Form.Control isInvalid={this.state.emptyField.includes('company')} type="text" placeholder="ကုမ္ပဏီအမည်အားထည့်သွင်းပါ" name="company" onChange={e => this.setState({ company: e.target.value })} />
                                        <Form.Control.Feedback type='invalid'>
                                            {'ကုမ္ပဏီအမည်အားဖြည့်ပါ'}
                                        </Form.Control.Feedback>
                                    </Col>
                                    <Col md={6} className="mb-4">
                                        <Form.Label className='col-md-12'>ရာထူး / အလုပ်အကိုင် <strong className='mandatorytxt'>*</strong></Form.Label>
                                        <Form.Control isInvalid={this.state.emptyField.includes('position')} type="text" placeholder="ရာထူး / အလုပ်အကိုင်အားထည့်သွင်းပါ" name="position" onChange={e => this.setState({ position: e.target.value })} />
                                        <Form.Control.Feedback type='invalid'>
                                            {'ရာထူး/အလုပ်အကိုင်အားဖြည့်ပါ'}
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group>
                                <Form.Group className="row">
                                    <Col md={6} className="mb-4">
                                        <Form.Label className='col-md-12'>သင်္ဘော အမည် <strong className='mandatorytxt'>*</strong></Form.Label>
                                        <Form.Control isInvalid={this.state.emptyField.includes('ship')} type="text" placeholder="သင်္ဘော အမည်အားထည့်သွင်းပါ" name="ship" onChange={e => this.setState({ ship: e.target.value })} />
                                        <Form.Control.Feedback type='invalid'>
                                            {'အမည်အားဖြည့်ပါ'}
                                        </Form.Control.Feedback>
                                    </Col>
                                    <Col md={6} className="mb-4">
                                        <Form.Label className='col-md-12'>သင်္ဘော အမျိုးအစား <strong className='mandatorytxt'>*</strong></Form.Label>
                                        <Form.Control isInvalid={this.state.emptyField.includes('shipType')} type="text" placeholder="သင်္ဘော အမျိုးအစား အားထည့်သွင်းပါ" name="shipType" onChange={e => this.setState({ shipType: e.target.value })} />
                                        <Form.Control.Feedback type='invalid'>
                                            {'အမျိုးအစားအားဖြည့်ပါ'}
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group>
                                <Form.Group className="row">
                                    <Col md={6} className="mb-4">
                                        <Form.Label className='col-md-12'>သင်္ဘော အလံ <strong className='mandatorytxt'>*</strong></Form.Label>
                                        <Form.Control isInvalid={this.state.emptyField.includes('shipFlag')} type="text" placeholder="သင်္ဘော အလံ အားထည့်သွင်းပါ" name="shipFlag" onChange={e => this.setState({ shipFlag: e.target.value })} />
                                        <Form.Control.Feedback type='invalid'>
                                            {'သင်္ဘောအလံအားဖော်ပြပါ'}
                                        </Form.Control.Feedback>
                                    </Col>
                                    <Col md={6} className="mb-4">
                                        <Form.Label className='col-md-12'>သင်္ဘော IMO နံပါတ် <strong className='mandatorytxt'>*</strong></Form.Label>
                                        <Form.Control isInvalid={this.state.emptyField.includes('imo')} type="text" placeholder="သင်္ဘော IMO နံပါတ်အားထည့်သွင်းပါ" name="imo" onChange={e => this.setState({ imoNo: e.target.value })} />
                                        <Form.Control.Feedback type='invalid'>
                                            {'IMO နံပါတ်အားဖြည့်ပါ'}
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group>
                                <Form.Group className="mb-4 row">
                                    <Col md={12}>
                                        <Form.Label className='col-md-12'>သင်္ဘော လက်ရှိရောက်နေသည့်နေရာ <strong className='mandatorytxt'>*</strong></Form.Label>
                                        <Form.Control isInvalid={this.state.emptyField.includes('location')} type="text" placeholder="လက်ရှိရောက်နေသည့်နေရာ အားထည့်သွင်းပါ" name="location" onChange={e => this.setState({ currentLoc: e.target.value })} />
                                        <Form.Control.Feedback type='invalid'>
                                            {'တည်နေရာအားဖြည့်ပါ'}
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group>
                                <Form.Group className="row">
                                    <Col md={6} className="mb-4">
                                        <Form.Label className='col-md-12'>နောက်ရောက်ရှိမည့် ဆိပ်ကမ်းအမည် <strong className='mandatorytxt'>*</strong></Form.Label>
                                        <Form.Control isInvalid={this.state.emptyField.includes('nextloc')} type="text" placeholder="ဆိပ်ကမ်းအမည် အားထည့်သွင်းပါ" name="nextloc" onChange={e => this.setState({ nextLoc: e.target.value })} />
                                        <Form.Control.Feedback type='invalid'>
                                            {'အမည်အားဖြည့်ပါ'}
                                        </Form.Control.Feedback>
                                    </Col>
                                    <Col md={6} className="mb-4">
                                        <Form.Label className='col-md-12'>ရောက်ရှိမည့် ခန့်မှန်းအချိန် <strong className='mandatorytxt'>*</strong></Form.Label>
                                        <Form.Control isInvalid={this.state.emptyField.includes('estTime')} type="date" name="estTime" onChange={e => this.setState({ estTime: e.target.value })} />
                                        <Form.Control.Feedback type='invalid'>
                                            {'ခန့်မှန်းရက်အားရွေးချယ်ပါ'}
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group>
                                <Form.Group className="row">
                                    <Col md={12} className="mb-4">
                                        <Form.Label>သင်္ဘောပေါ်ရှိ သင်္ဘောသားအရေအတွက် / နိုင်ငံသား <strong className='optionaltxt'>(Optional)</strong></Form.Label>
                                        <Form.Control type="text" placeholder="အရေအတွက် အားထည့်သွင်းပါ" name="seaman" onChange={e => this.setState({ seamanCount: e.target.value })} />
                                    </Col>
                                </Form.Group>
                                <Form.Group className="mb-4 row">
                                    <Col md={12} className="mb-4">
                                        <Form.Label>သင်္ဘောပေါ်ရှိ ကုန်ပစ္စည်းတန်ချိန် (သို့) အရေအတွက် <strong className='optionaltxt'>(Optional)</strong></Form.Label>
                                        <Form.Control type="text" placeholder="တန်ချိန် (သို့) အရေအတွက် အားထည့်သွင်းပါ" name="ton" onChange={e => this.setState({ tonCount: e.target.value })} />
                                    </Col>
                                </Form.Group>
                                <Form.Group className="row">
                                    <Col md={6} className="mb-4">
                                        <Form.Label className='col-md-12'>သင်္ဘောပိုင်ရှင် / Company အမည် <strong className='optionaltxt'>(Optional)</strong></Form.Label>
                                        <Form.Control type="text" placeholder="အမည်အားထည့်သွင်းပါ" name="owner" onChange={e => this.setState({ ownerName: e.target.value })} />
                                    </Col>
                                    <Col md={6} className="mb-4">
                                        <Form.Label className='col-md-12'>Company ကခန့်ထား​သော DPA အမည် <strong className='mandatorytxt'>*</strong></Form.Label>
                                        <Form.Control isInvalid={this.state.emptyField.includes('dpa')} type="text" placeholder="အမည်အားထည့်သွင်းပါ" name="dpa" onChange={e => this.setState({ dpaName: e.target.value })} />
                                        <Form.Control.Feedback type='invalid'>
                                            {'DPA အမည်အားဖြည့်ပါ'}
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group>
                                <Form.Group className="row">
                                    <Col md={6} className="mb-4">
                                        <Form.Label className='col-md-12'>DPA ၏ ဖုန်းနံပါတ် <strong className='mandatorytxt'>*</strong></Form.Label>
                                        <InputGroup className="mb-3">
                                            <Form.Control isInvalid={this.state.emptyField.includes('dpaPhone')} type="text" placeholder="ဖုန်းနံပါတ်အားထည့်သွင်းပါ" name="dpaPhone" onChange={e => this.setState({ dpaPhone: e.target.value })} />
                                            <InputGroup.Text id="basic-addon1">#</InputGroup.Text>
                                            <Form.Control.Feedback type='invalid'>
                                                {'DPA ဖုန်းနံပါတ်အားဖြည့်ပါ'}
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Col>
                                    <Col md={6} className="mb-4">
                                        <Form.Label className='col-md-12'>DPA ၏ အီး​မေးလ်လိပ်စာ <strong className='mandatorytxt'>*</strong></Form.Label>
                                        <InputGroup className="mb-3">
                                            <Form.Control isInvalid={this.state.emptyField.includes('dpaEmail')} type="text" placeholder="အီးမေးလ်လိပ်စာအားထည့်သွင်းပါ" name="dpaEmail" onChange={e => this.setState({ dpaEmail: e.target.value })} />
                                            <InputGroup.Text id="basic-addon1">@</InputGroup.Text>
                                            <Form.Control.Feedback type='invalid'>
                                                {'DPA အီးမေးလ်အားဖြည့်ပါ'}
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Col>
                                </Form.Group>
                                <Form.Group className="mb-4 row">
                                    <Col md={12}>
                                        <Form.Label>ပြဿနာဖြစ်ပွားပုံ (တတ်နိုင်လျှင်အသေးစိတ်ဖော်ပြပေးပါရန်) <strong className='mandatorytxt'>*</strong></Form.Label>
                                        <Form.Control
                                            className="form-control"
                                            name="cmbody"
                                            isInvalid={this.state.emptyField.includes('description')}
                                            id="exampleFormControlTextarea1"
                                            rows="5"
                                            onChange={e => this.setState({ description: e.target.value })} as="textarea" />
                                        <Form.Control.Feedback type='invalid'>
                                            {'ဖြစ်ပွားပုံအားရေးပါ'}
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group>
                                <Form.Group className="row">
                                    <Col md={6} className="mb-4">
                                        <Form.Label className='col-md-12'>ပြဿနာစတင်ဖြစ်ပွားသည့် နေ့ရက် <strong className='mandatorytxt'>*</strong></Form.Label>
                                        <Form.Control isInvalid={this.state.emptyField.includes('problemstart')} type="date" name="problemstart" onChange={e => this.setState({ problemstartDate: e.target.value })} />
                                        <Form.Control.Feedback type='invalid'>
                                            {'နေ့ရက်အားရွေးချယ်ပါ'}
                                        </Form.Control.Feedback>
                                    </Col>
                                    <Col md={6} className="mb-4">
                                        <Form.Label className='col-md-12'>ထုတ်ပယ်/ပြန်ပို့ခံရသည့်နေ့ရက် <strong className='optionaltxt'>(Optional)</strong></Form.Label>
                                        <Form.Control type="date" name="goback" onChange={e => this.setState({ goBackDate: e.target.value })} />
                                    </Col>
                                </Form.Group>
                                <Form.Group className="row">
                                    <Col md={12} className="mb-4">
                                        <Form.Label className='col-md-12'>လက်ရှိသင်္ဘောတွင်စတင်တာဝန်ထမ်းဆောင်သည့်နေ့ရက် <strong className='mandatorytxt'>*</strong></Form.Label>
                                        <Form.Control isInvalid={this.state.emptyField.includes('start')} type="date" name="start" onChange={e => this.setState({ startDate: e.target.value })} />
                                        <Form.Control.Feedback type='invalid'>
                                            {'နေ့ရက်အားရွေးချယ်ပါ'}
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group>
                                <Form.Group className="row">
                                    <Col md={12} className="mb-4">
                                        <Form.Label className='col-md-12'>Contract ပါတာဝန်ထမ်းဆောင်မှုပြီးဆုံးမည့်နေ့ရက် <strong className='mandatorytxt'>*</strong></Form.Label>
                                        <Form.Control isInvalid={this.state.emptyField.includes('end')} type="date" name="end" onChange={e => this.setState({ contractEndDate: e.target.value })} />
                                        <Form.Control.Feedback type='invalid'>
                                            {'နေ့ရက်အားရွေးချယ်ပါ'}
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group>
                                <Form.Group className="row">
                                    <Col md={12} className="mb-4">
                                        <Form.Label>အခြားသင်္ဘောတွင် အလားတူပြဿနာမျိုး ကြုံတွေ့ဖူးခြင်းရှိပါသလား (အသေးစိတ်ဖော်ပြရန်) <strong className='optionaltxt'>(Optional)</strong></Form.Label>
                                        <textarea className="form-control" id="exampleFormControlTextarea1" rows="5" name="desSame" onChange={e => this.setState({ desSame: e.target.value })}></textarea>
                                        <Form.Control.Feedback type='invalid'>
                                            {'ရှိ/မရှိ နှင့် ရှိပါကဖြစ်ပွားပုံအားရေးပါ'}
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group>
                                <Form.Group className="row">
                                    <Form.Label className='col-md-12'>ဆက်သွယ်နိုင်မည့်ဖုန်းနံပါတ် <strong className='mandatorytxt'>*</strong>
                                    </Form.Label>
                                    <Col md={4} className="mb-3">
                                        <Form.Select isInvalid={this.state.emptyField.includes('contact')} aria-label="‌-- ရွေးချယ်ပါ။  --" onChange={(e) => { setCommunication(e.target.value) }}>
                                            <option>‌-- ရွေးချယ်ပါ။  --</option>
                                            <option value="Mobile">Mobile</option>
                                            <option value="Viber">Viber</option>
                                            <option value="Whatapps">WhatsApp</option>
                                            <option value="Line">Line</option>
                                            <option value="Telegram">Telegram</option>
                                            <option value="Signal">Signal</option>
                                            <option value="WeChat ">WeChat</option>
                                        </Form.Select>
                                        <Form.Control.Feedback type='invalid'>
                                            {'ရွေးချယ်ပါ'}
                                        </Form.Control.Feedback>
                                    </Col>
                                    <Col md={8} className="mb-4">
                                        <InputGroup className="mb-3">
                                            <Form.Control isInvalid={this.state.emptyField.includes('phone')} type="text" placeholder="ဖုန်းနံပါတ်အားထည့်သွင်းပါ" name="phone" onChange={e => this.setState({ contactNumber: e.target.value })} />
                                            <InputGroup.Text id="basic-addon1">#</InputGroup.Text>
                                            <Form.Control.Feedback type='invalid'>
                                                {'ဖုန်းနံပါတ်အားဖြည့်ပါ'}
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Col>
                                </Form.Group>
                                <Form.Group className='mb-4 row'>
                                    <Col md={12}>
                                        <Form.Label className='col-md-8'>အီးမေးလ် လိပ်စာ <strong className='optionaltxt'>(Optional)</strong></Form.Label>
                                        <InputGroup className="mb-3">
                                            <Form.Control type="text" placeholder="သင်၏အီးမေးလ်လိပ်စာအားထည့်သွင်းပါ" name="email" onChange={e => this.setState({ email: e.target.value })} />
                                            <InputGroup.Text id="basic-addon1">@</InputGroup.Text>
                                        </InputGroup>
                                    </Col>
                                </Form.Group>
                                <Form.Group className="mb-4 row">
                                    <Col md={12}>
                                        <Form.Label>အခြားသောအကြောင်းအရာများ <strong className='optionaltxt'>(Optional)</strong></Form.Label>
                                        <textarea className="form-control" id="exampleFormControlTextarea1" rows="5" name="other" onChange={e => this.setState({ otherDes: e.target.value })}></textarea>
                                    </Col>
                                </Form.Group>
                                <Form.Group className={`row ${this.state.isMobile === false ? "mb-4" : "mb-2"}`}>
                                    <Col md={12} className="d-grid gap-2">
                                        <Button variant="primary" className="mt-3 btn-lg" size="lg" onClick={checkValidation}> Submit </Button>
                                        <DataModal show={this.state.showModal} onHide={() => this.setState({ showModal: false })} data={this.state} />
                                    </Col>
                                </Form.Group>
                            </Form>
                            <Footer />
                        </Container>
                    ) : (
                        <LoadingSceen />
                    )
                }
            </>
        )
    }
}

function DataModal(props) {
    const submitData = () => {
        submitSeafarerData(props.data).then((res) => {
            console.log(res)
            if (res.code === 200) {
                localStorage.setItem("code", res.code);
                localStorage.setItem("message", res.message);
            } else {
                localStorage.setItem("code", res.code);
                localStorage.setItem("message", res.message);
            }
            window.location = '/success?seafarercomplaint';
        });
    }
    return (
        <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    အချက်အလက်များအားစစ်ဆေးပါ။
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group className="mb-4 row">
                    <Col md={6}>
                        <Form.Label className='mb-2'> <strong> အမည်  </strong></Form.Label>
                    </Col>
                    <Col md={6}>
                        <Form.Label className='mb-2'> : {props.data.name} </Form.Label>
                    </Col>
                </Form.Group>
                <Form.Group className="mb-4 row">
                    <Col md={6}>
                        <Form.Label className='mb-2'> <strong> နိုင်ငံသား  </strong></Form.Label>
                    </Col>
                    <Col md={6}>
                        <Form.Label className='mb-2'> : {props.data.nationality} </Form.Label>
                    </Col>
                </Form.Group>
                <Form.Group className="mb-4 row">
                    <Col md={6}>
                        <Form.Label className='mb-2'> <strong> Passport နံပါတ်  </strong></Form.Label>
                    </Col>
                    <Col md={6}>
                        <Form.Label className='mb-2'> : {props.data.passport} </Form.Label>
                    </Col>
                </Form.Group>
                <Form.Group className="mb-4 row">
                    <Col md={6}>
                        <Form.Label className='mb-2'> <strong> CDC အမှတ်  </strong></Form.Label>
                    </Col>
                    <Col md={6}>
                        <Form.Label className='mb-2'> : {props.data.cdcNo} </Form.Label>
                    </Col>
                </Form.Group>
                <Form.Group className="mb-4 row">
                    <Col md={6}>
                        <Form.Label className='mb-2'> <strong> မြန်မာနိုင်ငံမှအလုပ်ခန့်သည့်ကုမ္ပဏီ </strong></Form.Label>
                    </Col>
                    <Col md={6}>
                        <Form.Label className='mb-2'> : {props.data.company} </Form.Label>
                    </Col>
                </Form.Group>
                <Form.Group className="mb-4 row">
                    <Col md={6}>
                        <Form.Label className='mb-2'> <strong> ရာထူး/အလုပ်အကိုင် </strong></Form.Label>
                    </Col>
                    <Col md={6}>
                        <Form.Label className='mb-2'> : {props.data.position} </Form.Label>
                    </Col>
                </Form.Group>
                <Form.Group className="mb-4 row">
                    <Col md={6}>
                        <Form.Label className='mb-2'> <strong> သင်္ဘော အမည် </strong></Form.Label>
                    </Col>
                    <Col md={6}>
                        <Form.Label className='mb-2'> : {props.data.ship} </Form.Label>
                    </Col>
                </Form.Group>
                <Form.Group className="mb-4 row">
                    <Col md={6}>
                        <Form.Label className='mb-2'> <strong> သင်္ဘော အမျိုးအစား</strong></Form.Label>
                    </Col>
                    <Col md={6}>
                        <Form.Label className='mb-2'> : {props.data.shipType} </Form.Label>
                    </Col>
                </Form.Group>
                <Form.Group className="mb-4 row">
                    <Col md={6}>
                        <Form.Label className='mb-2'> <strong> သင်္ဘော အလံ</strong></Form.Label>
                    </Col>
                    <Col md={6}>
                        <Form.Label className='mb-2'> : {props.data.shipFlag} </Form.Label>
                    </Col>
                </Form.Group>
                <Form.Group className="mb-4 row">
                    <Col md={6}>
                        <Form.Label className='mb-2'> <strong> သင်္ဘော၏ IMO နံပါတ်</strong></Form.Label>
                    </Col>
                    <Col md={6}>
                        <Form.Label className='mb-2'> : {props.data.imoNo} </Form.Label>
                    </Col>
                </Form.Group>
                <Form.Group className="mb-4 row">
                    <Col md={6}>
                        <Form.Label className='mb-2'> <strong> သင်္ဘော လက်ရှိရောက်နေသည့်နေရာ </strong></Form.Label>
                    </Col>
                    <Col md={6}>
                        <Form.Label className='mb-2'> : {props.data.currentLoc} </Form.Label>
                    </Col>
                </Form.Group>
                <Form.Group className="mb-4 row">
                    <Col md={6}>
                        <Form.Label className='mb-2'> <strong> နောက်ရောက်ရှိမည့် ဆိပ်ကမ်းအမည် </strong></Form.Label>
                    </Col>
                    <Col md={6}>
                        <Form.Label className='mb-2'> : {props.data.nextLoc} </Form.Label>
                    </Col>
                </Form.Group>
                <Form.Group className="mb-4 row">
                    <Col md={6}>
                        <Form.Label className='mb-2'> <strong> ရောက်ရှိမည့် ခန့်မှန်းအချိန် </strong></Form.Label>
                    </Col>
                    <Col md={6}>
                        <Form.Label className='mb-2'> : {props.data.estTime} </Form.Label>
                    </Col>
                </Form.Group>
                <Form.Group className="mb-4 row">
                    <Col md={6}>
                        <Form.Label className='mb-2'> <strong> သင်္ဘောပေါ်ရှိ သင်္ဘောသားအရေအတွက် / နိုင်ငံသား </strong></Form.Label>
                    </Col>
                    <Col md={6}>
                        <Form.Label className='mb-2'> : {props.data.seamanCount} </Form.Label>
                    </Col>
                </Form.Group>
                <Form.Group className="mb-4 row">
                    <Col md={6}>
                        <Form.Label className='mb-2'> <strong> သင်္ဘောပေါ်ရှိ ကုန်ပစ္စည်းတန်ချိန် (သို့) အရေအတွက် </strong></Form.Label>
                    </Col>
                    <Col md={6}>
                        <Form.Label className='mb-2'> : {props.data.tonCount} </Form.Label>
                    </Col>
                </Form.Group>
                <Form.Group className="mb-4 row">
                    <Col md={6}>
                        <Form.Label className='mb-2'> <strong> သင်္ဘောပိုင်ရှင် / Company အမည် </strong></Form.Label>
                    </Col>
                    <Col md={6}>
                        <Form.Label className='mb-2'> : {props.data.ownerName} </Form.Label>
                    </Col>
                </Form.Group>
                <Form.Group className="mb-4 row">
                    <Col md={6}>
                        <Form.Label className='mb-2'> <strong> Company ကခန့်ထား​သော DPA အမည် </strong></Form.Label>
                    </Col>
                    <Col md={6}>
                        <Form.Label className='mb-2'> : {props.data.dpaName} </Form.Label>
                    </Col>
                </Form.Group>
                <Form.Group className="mb-4 row">
                    <Col md={6}>
                        <Form.Label className='mb-2'> <strong> DPA ၏ ဖုန်းနံပါတ် </strong></Form.Label>
                    </Col>
                    <Col md={6}>
                        <Form.Label className='mb-2'> : {props.data.dpaPhone} </Form.Label>
                    </Col>
                </Form.Group>
                <Form.Group className="mb-4 row">
                    <Col md={6}>
                        <Form.Label className='mb-2'> <strong> DPA ၏ အီး​မေးလ်လိပ်စာ </strong></Form.Label>
                    </Col>
                    <Col md={6}>
                        <Form.Label className='mb-2'> : {props.data.dpaEmail} </Form.Label>
                    </Col>
                </Form.Group>
                <Form.Group className="mb-4 row">
                    <Col md={6}>
                        <Form.Label className='mb-2'> <strong> ပြဿနာဖြစ်ပွားပုံ (အသေးစိတ်) </strong></Form.Label>
                    </Col>
                    <Col md={6}>
                        <Form.Label className='mb-2'> : {props.data.description} </Form.Label>
                    </Col>
                </Form.Group>
                <Form.Group className="mb-4 row">
                    <Col md={6}>
                        <Form.Label className='mb-2'> <strong> ပြဿနာစတင်ဖြစ်ပွားသည့် နေ့ရက်</strong></Form.Label>
                    </Col>
                    <Col md={6}>
                        <Form.Label className='mb-2 d-block'> : {props.data.problemstartDate} </Form.Label>
                    </Col>
                </Form.Group>
                <Form.Group className="mb-4 row">
                    <Col md={6}>
                        <Form.Label className='mb-2'> <strong> ထုတ်ပယ်/ပြန်ပို့ခံရသည့်နေ့ရက်</strong></Form.Label>
                    </Col>
                    <Col md={6}>
                        <Form.Label className='mb-2 d-block'> : {props.data.goBackDate} </Form.Label>
                    </Col>
                </Form.Group>
                <Form.Group className="mb-4 row">
                    <Col md={6}>
                        <Form.Label className='mb-2'> <strong> လက်ရှိသင်္ဘောတွင်စတင်တာဝန်ထမ်းဆောင်သည့်နေ့ရက်</strong></Form.Label>
                    </Col>
                    <Col md={6}>
                        <Form.Label className='mb-2 d-block'> : {props.data.startDate} </Form.Label>
                    </Col>
                </Form.Group>
                <Form.Group className="mb-4 row">
                    <Col md={6}>
                        <Form.Label className='mb-2'> <strong> Contract ပါတာဝန်ထမ်းဆောင်မှုပြီးဆုံးမည့်နေ့ရက်</strong></Form.Label>
                    </Col>
                    <Col md={6}>
                        <Form.Label className='mb-2 d-block'> : {props.data.contractEndDate} </Form.Label>
                    </Col>
                </Form.Group>
                <Form.Group className="mb-4 row">
                    <Col md={6}>
                        <Form.Label className='mb-2'> <strong>အလားတူပြဿနာမျိုး ပြဿနာဖြစ်ပွားပုံ (အသေးစိတ်) </strong></Form.Label>
                    </Col>
                    <Col md={6}>
                        <Form.Label className='mb-2'> : {props.data.desSame} </Form.Label>
                    </Col>
                </Form.Group>
                <Form.Group className="mb-4 row">
                    <Col md={6}>
                        <Form.Label className='mb-2'> <strong> ဆက်သွယ်နိုင်မည့်ဖုန်းနံပါတ် </strong></Form.Label>
                    </Col>
                    <Col md={6}>
                        <Form.Label className='mb-2 d-block'> : {props.data.contact} - {props.data.contactNumber} </Form.Label>
                    </Col>
                </Form.Group>
                <Form.Group className="mb-4 row">
                    <Col md={6}>
                        <Form.Label className='mb-2'> <strong> အီးမေးလ် လိပ်စာ </strong></Form.Label>
                    </Col>
                    <Col md={6}>
                        <Form.Label className='mb-2 d-block'> : {props.data.email} </Form.Label>
                    </Col>
                </Form.Group>
                <Form.Group className="mb-4 row">
                    <Col md={6}>
                        <Form.Label className='mb-2'> <strong>အခြားသောအကြောင်းအရာများ </strong></Form.Label>
                    </Col>
                    <Col md={6}>
                        <Form.Label className='mb-2'> : {props.data.otherDes} </Form.Label>
                    </Col>
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.onHide}>Cancel</Button>
                <Button onClick={submitData}>Confirm</Button>
            </Modal.Footer>
        </Modal>
    );
}