import React, { Component } from 'react';
import { FaExternalLinkAlt } from "react-icons/fa";
import { Container, Row, Col } from 'react-bootstrap';
import "./TopBar.css";
import officeicn from "../../Images/officeicn.svg";
import lockicn from "../../Images/lockicn.svg";
import greenlockicn from "../../Images/greenlockicn.svg";

export default class TopBar extends Component {
    render() {
        return (
            <Container className='nugstart' fluid>
                <Container>
                    <div>
                        <img src="https://assets-mol.nugmyanmar.org/images/2021/12/state_logo_flat-01.svg" alt="Myanmar State Emblem" width="30" height="25"></img>
                        <a className='nug hypernug' href="https://nugmyanmar.org" target="_blank" rel="noopener"><span>An Official Website of the National Unity Government</span></a>
                        <a className='nug' data-bs-toggle="collapse" href="#collapse" role="button" aria-expanded="false" aria-controls="collapse">
                            How to identify?
                        </a>
                    </div>
                    <div className="collapse" id="collapse">
                        <div className='nugtop'>
                            <Row>
                                <Col md={6}>
                                    <Col>
                                        <img src={officeicn} className="banner-icon"></img>
                                    </Col>
                                    <Col>
                                        <strong className='subbannertitle'> NUG Official website links usually end with .nugmyanmar.org</strong>
                                        <p className='nugctext'>Ministry of Labour communicate via <strong>.nugmyanmar.org</strong> or <strong>.molmyanmar.org </strong>websites. <a href="https://go.molmyanmar.org/trusted-sites" className="banner-content-text bp-masthead-button-link bp-masthead-button-text link" target="_blank" rel="noreferrer">Trusted websites <FaExternalLinkAlt /></a></p>
                                    </Col>
                                </Col>
                                <Col md={6}>
                                    <Col>
                                        <img src={lockicn} className="banner-icon"></img>
                                    </Col>
                                    <Col>
                                        <strong className='subbannertitle'> Secure websites use HTTPS</strong>
                                        <p className='nugctext'>Look for a <strong> lock </strong>(<img src={lockicn} className="inline-banner-icon innericon"></img>) or https:// as an added precaution. Share sensitive information only on official, secure websites.</p>
                                    </Col>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Container>
            </Container>
        );
    }
}