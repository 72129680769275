import React, { Component } from 'react';
import concernlogo from "../../Images/concernlogo.png";
import successjson from "../../Images/success.json";
import errorjson from "../../Images/error.json";
import LoadingSceen from '../Loading/Loading';
import './FormSuccess.css';
import { Player } from '@lottiefiles/react-lottie-player';

export default class LocalComplaint extends Component {
    state = {
        "loading": true,
        "code": localStorage.getItem("code"),
        "message": localStorage.getItem("message")
    }
    componentDidMount() {
        setTimeout(() => this.setState({ loading: false }), 2000);
    }
    render() {
        console.log(this.state.code)
        const goBackHome = () => {
            window.location = '/showHome';
            localStorage.removeItem("code");
            localStorage.removeItem("message");
        };
        return (
            <>
                {this.state.loading === false ? (
                    <div className="App" >
                        <header className="bg animate mheight">
                            <div className='d-flex'>
                                <button className='btn btn-primary m-3' onClick={goBackHome}>Back To Home</button>
                            </div>
                            {this.state.code === "200" ? (
                                <div className="text-Box">
                                    <Player
                                        autoplay={true}
                                        loop={true}
                                        src={successjson}
                                        speed="0.5"
                                        style={{ height: '250px', width: '250px' }}
                                    ></Player>
                                    <p className='h4 lh-lg'>
                                        သင်၏တိုင်ကြားမှုအတွက် အချက်အလက်ပေးပို့ခြင်း လုပ်ငန်းစဉ်အား အောင်မြင်စွာပြုလုပ်ပြီးဖြစ်ပါသည်။
                                    </p>
                                    <p className='h6 lh-lg'>
                                        ကျွန်ုပ်တို့ အလုပ်သမားဝန်ကြီးဌာန၊ တိုင်ကြားရေးယန္တရားအဖွဲ့မှအမြန်ဆုံးပြန်လည်ဆက်သွယ်ပေးပါမည်။
                                    </p>
                                </div>
                            ) : (
                                <div className="text-danger text-Box">
                                    <Player
                                        autoplay={true}
                                        loop={true}
                                        src={errorjson}
                                        style={{ height: '250px', width: '250px' }}
                                    ></Player>
                                    <p className='h4 lh-lg'>
                                        အချက်အလက်ပေးပို့ခြင်း လုပ်ငန်းစဉ်မှာ အောင်မြင်မှုမရှိပါ အကြောင်းမှာ
                                    </p>
                                    {this.state.message === null ? (
                                        "ဖောင်ဖြည့်သွင်းထားမှုမရှိခြင်း"
                                    ) : (this.state.message)}
                                    {/* {this.state.message} */}
                                </div>
                            )}
                        </header>
                    </div>
                ) : (
                    <LoadingSceen />
                )}
            </>
        );
    }
}