import React, { Component } from 'react';
import { Container, Form, Col, Button, Image, Modal, FormControl } from 'react-bootstrap';
import './OverseaComplaint.css';
import { submitOverseaData } from '../../firebase';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import TopBar from "../TopBar/TopBar"
import LoadingSceen from '../Loading/Loading';
import concernlogo from "../../Images/concernlogo.png";

export default class OverseaComplaint extends Component {
    state = {
        "showModal": false,
        "loading": true,
        "country": null,
        "other": null,
        "name": null,
        "passport": null,
        "factoryname": null,
        "factoryaddress": null,
        "title": null,
        "description": null,
        "contact": [],
        "contactInfo": {
            "phone": null,
            "viber": null,
            "line": null,
            "messenger": null,
        },
        "timestamp": new Date().toLocaleString('en-US', { timeZone: 'Asia/Yangon' }),
        "success": null,
        "errror": null,
        "emptyField": [],
        "isMobile": window.matchMedia("only screen and (max-width: 760px)").matches
    }

    componentDidMount() {
        setTimeout(() => this.setState({ loading: false }), 3000);
    }

    render() {
        const handleMobile = () => {
            const getMobile = window.matchMedia("only screen and (max-width: 760px)").matches;
            this.setState({ isMobile: getMobile })
        };
        window.addEventListener('resize', handleMobile);

        const setCountry = (val) => {
            this.setState({ country: val });
            if (val === "အခြား") {
                this.setState({ other: val });
            }
        }
        const setContact = (ele) => {
            if (ele.checked === true) {
                this.setState(prevState => ({
                    contact: [...prevState.contact, ele.value]
                }))
            } else {
                const arr = this.state.contact.filter((item) => item !== ele.value);
                this.setState({ contact: arr });
            }
        }
        const checkValidation = () => {
            if (this.state.country === null) {
                console.log(this.state.country)
                this.setState(prevState => ({
                    emptyField: [...prevState.emptyField, "country"]
                }))
            } else {
                const errArr = this.state.emptyField.filter((item) => item !== "country");
                this.setState({ emptyField: errArr });
            }
            if (this.state.name === null) {
                this.setState(prevState => ({
                    emptyField: [...prevState.emptyField, "name"]
                }))
                let element = document.getElementsByName("username");
                element[0].focus();
            } else {
                const errArr = this.state.emptyField.filter((item) => item !== "name");
                this.setState({ emptyField: errArr });
            }
            if (this.state.passport === null) {
                this.setState(prevState => ({
                    emptyField: [...prevState.emptyField, "passport"]
                }))
                let element = document.getElementsByName("passport");
                element[0].focus();
            } else {
                const errArr = this.state.emptyField.filter((item) => item !== "passport");
                this.setState({ emptyField: errArr });
            }
            if (this.state.factoryname === null) {
                this.setState(prevState => ({
                    emptyField: [...prevState.emptyField, "factoryname"]
                }))
                let element = document.getElementsByName("factoryname");
                element[0].focus();
            } else {
                const errArr = this.state.emptyField.filter((item) => item !== "factoryname");
                this.setState({ emptyField: errArr });
            }
            if (this.state.factoryaddress === null) {
                this.setState(prevState => ({
                    emptyField: [...prevState.emptyField, "factoryaddress"]
                }))
                let element = document.getElementsByName("factoryaddress");
                element[0].focus();
            } else {
                const errArr = this.state.emptyField.filter((item) => item !== "factoryaddress");
                this.setState({ emptyField: errArr });
            }
            if (this.state.title === null) {
                this.setState(prevState => ({
                    emptyField: [...prevState.emptyField, "title"]
                }))
                let element = document.getElementsByName("title");
                element[0].focus();
            } else {
                const errArr = this.state.emptyField.filter((item) => item !== "title");
                this.setState({ emptyField: errArr });
            }
            if (this.state.description === null) {
                this.setState(prevState => ({
                    emptyField: [...prevState.emptyField, "description"]
                }))
                let element = document.getElementsByName("description");
                element[0].focus();
            } else {
                const errArr = this.state.emptyField.filter((item) => item !== "description");
                this.setState({ emptyField: errArr });
            }
            if (this.state.contact.length === 0) {
                this.setState(prevState => ({
                    emptyField: [...prevState.emptyField, "contact"]
                }))
            } else {
                const errArr = this.state.emptyField.filter((item) => item !== "contact");
                this.setState({ emptyField: errArr });
            }

            if (this.state.country !== null && this.state.name !== null && this.state.passport !== null && this.state.factoryname !== null && this.state.factoryaddress !== null && this.state.title !== null && this.state.description !== null && this.state.contact.length >= 1) {
                this.setState({ showModal: true });
            }
        }

        const setContactInfo = (ele) => {
            if (ele.name === "phone") {
                this.setState(prevState => ({
                    contactInfo: { ...prevState.contactInfo, "phone": ele.value }
                }))
            } else if (ele.name === "viber") {
                this.setState(prevState => ({
                    contactInfo: { ...prevState.contactInfo, "viber": ele.value }
                }))
            } else if (ele.name === "line") {
                this.setState(prevState => ({
                    contactInfo: { ...prevState.contactInfo, "line": ele.value }
                }))
            } else {
                this.setState(prevState => ({
                    contactInfo: { ...prevState.contactInfo, "messenger": ele.value }
                }))
            }
        }
        console.log(this.state.emptyField)
        return (
            <>
                {this.state.loading === false ? (
                    <Container fluid className='bg p-0'>
                        <TopBar />
                        <Header title={"ပြည်ပအလုပ်သမားတိုင်ကြားရေးယန္တရား"} subtitle={"ပြည်ပအလုပ်သမားရေးရာကိစ္စရပ်များနှင့်ပတ်သက်၍တိုင်ကြားနိုင်ရန်"} />
                        <Form className={`container bg-body rounded ${this.state.isMobile === false ? "shadow col-lg-8 my-5 p-5" : "mx-2.5 p-4 mb-4"}`}>
                            <Form.Group className="mb-4 row">
                                <Col md={12}>
                                    <Form.Label className='mb-2'> နိုင်ငံအားရွေးချယ်ပါ။ <strong className='mandatorytxt'>*</strong></Form.Label>
                                    <Form.Select
                                        isInvalid={this.state.emptyField.includes('country')}
                                        aria-label="‌-- ရွေးချယ်ပါ။  --"
                                        onChange={(e) => { setCountry(e.target.value) }}>
                                        <option>‌-- ရွေးချယ်ပါ။  --</option>
                                        <option value="ထိုင်းနိုင်ငံ">ထိုင်းနိုင်ငံ</option>
                                        <option value="မလေးရှားနိုင်ငံ">မလေးရှားနိုင်ငံ</option>
                                        <option value="ကိုရီးယားသမ္မတနိုင်ငံ">ကိုရီးယားသမ္မတနိုင်ငံ</option>
                                        <option value="စင်ကာပူနိုင်ငံ">စင်ကာပူနိုင်ငံ</option>
                                        <option value="အခြား">အခြား</option>
                                    </Form.Select>
                                    <Form.Control.Feedback type='invalid'>
                                        {'နိုင်ငံအားရွေးချယ်ပါ'}
                                    </Form.Control.Feedback>
                                    {/* {this.state.emptyField.includes('country') &&
                                            <Form.Text className='mt-2'> Please choose your country! </Form.Text>
                                        } */}
                                </Col>
                            </Form.Group>
                            {this.state.other === "အခြား" &&
                                <Form.Group className="mb-4 row">
                                    <Col md={12}>
                                        <Form.Label className='mb-2'>အခြားနိုင်ငံအားဖော်ပြပါ။ <strong className='mandatorytxt'>*</strong></Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="country"
                                            isInvalid={this.state.emptyField.includes('country')}
                                            onChange={(e) => { setCountry(e.target.value) }} />
                                        <Form.Control.Feedback type='invalid'>
                                            {'အခြားနိုင်ငံအားဖော်ပြပါ'}
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group>
                            }
                            <Form.Group className="mb-4 row">
                                <Col md={6}>
                                    <Form.Label className='mb-2'>အမည် <strong className='mandatorytxt'>*</strong></Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="username"
                                        isInvalid={this.state.emptyField.includes('name')}
                                        onChange={e => this.setState({ name: e.target.value })} />
                                    {/* {this.state.emptyField.includes('name') &&
                                            <Form.Text className='mt-2'> Please Fill your name! </Form.Text>
                                        } */}
                                    <Form.Control.Feedback type='invalid'>
                                        {'အမည်အားဖြည့်သွင်းပါ'}
                                    </Form.Control.Feedback>
                                </Col>
                                <Col md={6}>
                                    <Form.Label className='mb-2'>Passport / CI နံပါတ် <strong className='mandatorytxt'>*</strong></Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="passport"
                                        isInvalid={this.state.emptyField.includes('passport')}
                                        onChange={e => this.setState({ passport: e.target.value })} />
                                    {/* {this.state.emptyField.includes('passport') &&
                                            <Form.Text className='mt-2'> Please Fill your passport Id! </Form.Text>
                                        } */}
                                    <Form.Control.Feedback type='invalid'>
                                        {'Passport (သို့) CI နံပါတ်အားထည့်သွင်းပါ'}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group className="mb-4 row">
                                <Col md={12}>
                                    <Form.Label className='mb-2'>စက်ရုံ (သို့) လုပ်ငန်း အမည် <strong className='mandatorytxt'>*</strong></Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="factoryname"
                                        isInvalid={this.state.emptyField.includes('factoryname')}
                                        onChange={e => this.setState({ factoryname: e.target.value })} />
                                    {/* {this.state.emptyField.includes('factoryname') &&
                                            <Form.Text className='mt-2'> Please Fill your factoryname! </Form.Text>
                                        } */}
                                    <Form.Control.Feedback type='invalid'>
                                        {'စက်ရုံအမည်အားထည့်သွင်းပါ'}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group className="mb-4 row">
                                <Col md={12}>
                                    <Form.Label className='mb-2'>စက်ရုံ (သို့) လုပ်ငန်း တည်နေရာ <strong className='mandatorytxt'>*</strong></Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="factoryaddress"
                                        isInvalid={this.state.emptyField.includes('factoryaddress')}
                                        onChange={e => this.setState({ factoryaddress: e.target.value })} />
                                    {/* {this.state.emptyField.includes('factoryaddress') &&
                                            <Form.Text className='mt-2'> Please Fill your factoryaddress! </Form.Text>
                                        } */}
                                    <Form.Control.Feedback type='invalid'>
                                        {'တည်နေရာအားထည့်သွင်းပါ'}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group className="mb-4 row">
                                <Col md={12}>
                                    <Form.Label className='mb-2'>တိုင်ကြားလိုသည့် အကြောင်းအရာ <strong className='mandatorytxt'>*</strong></Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="title"
                                        isInvalid={this.state.emptyField.includes('title')}
                                        onChange={e => this.setState({ title: e.target.value })} />
                                    {/* {this.state.emptyField.includes('title') &&
                                            <Form.Text className='mt-2'> Please Fill your CM Title! </Form.Text>
                                        } */}
                                    <Form.Control.Feedback type='invalid'>
                                        {'တိုင်ကြားလိုသည့်အကြောင်းအရာအားဖြည့်သွင်းပါ'}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group className="mb-4 row">
                                <Col md={12}>
                                    <Form.Label className='mb-2'>အမှုဖြစ်စဉ် <strong className='mandatorytxt'>*</strong></Form.Label>
                                    <FormControl
                                        as="textarea"
                                        id="exampleFormControlTextarea1"
                                        rows="5"
                                        isInvalid={this.state.emptyField.includes('description')}
                                        onChange={e => this.setState({ description: e.target.value })}
                                    />
                                    {/* <textarea
                                            className="form-control"
                                            id="exampleFormControlTextarea1"
                                            rows="5"
                                            onChange={e => this.setState({ description: e.target.value })} ></textarea> */}
                                    {/* {this.state.emptyField.includes('description') &&
                                            <Form.Text className='mt-2'> Please Fill your CM Description! </Form.Text>
                                        } */}
                                    <Form.Control.Feedback type='invalid'>
                                        {'အမှုဖြစ်စဉ်အားဖြည့်သွင်းပါ'}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group className="mb-4 row">
                                <Form.Label className='mb-2'>ဆက်သွယ်ရန် လိပ်စာ <strong className='mandatorytxt'>*</strong></Form.Label>
                                <Col md={6}>
                                    <Form.Check type="checkbox" value="phone" className='mb-3' label="ဖုန်း" onChange={(e) => { setContact(e.target) }} />
                                    <Form.Check type="checkbox" value="line" className='mb-3' label="Line" onChange={(e) => { setContact(e.target) }} />
                                </Col>
                                <Col md={6}>
                                    <Form.Check type="checkbox" value="viber" className='mb-3' label="Viber" onChange={(e) => { setContact(e.target) }} />
                                    <Form.Check type="checkbox" value='messenger' className='mb-3' label="Messenger" onChange={(e) => { setContact(e.target) }} />
                                </Col>
                                {this.state.emptyField.includes('contact') &&
                                    <Form.Text className='mt-2'> ဆက်သွယ်ရန်လိပ်စာအား ရွေးချယ်ပါ </Form.Text>
                                }
                            </Form.Group>
                            {this.state.contact.includes('phone') === true &&
                                <Form.Group className="mb-4 row">
                                    <Col md={12}>
                                        <Form.Label className='mb-2'>ဖုန်းနံပါတ် <strong className='mandatorytxt'>*</strong></Form.Label>
                                        <Form.Control
                                            placeholder="(000) 000-0000"
                                            type="tel" name="phone"
                                            onChange={(e) => { setContactInfo(e.target) }} />
                                        <label className='sm-label'>မှန်ကန်သောဖုန်းနံပါတ်အားဖြည့်သွင်းပါ။</label>
                                    </Col>
                                </Form.Group>
                            }
                            {this.state.contact.includes('line') === true &&
                                <Form.Group className="mb-4 row">
                                    <Col md={12}>
                                        <Form.Label className='mb-2'>Line <strong className='mandatorytxt'>*</strong></Form.Label>
                                        <Form.Control placeholder="(000) 000-0000" type="tel" name="line" onChange={(e) => { setContactInfo(e.target) }} />
                                        <label className='sm-label'>Line ဖွင့်လှစ်ထားသည့်ဖုန်းနံပါတ်အားထည့်သွင်းပါ။</label>
                                    </Col>
                                </Form.Group>
                            }
                            {this.state.contact.includes('viber') === true &&
                                <Form.Group className="mb-4 row">
                                    <Col md={12}>
                                        <Form.Label className='mb-2'>Viber <strong className='mandatorytxt'>*</strong></Form.Label>
                                        <Form.Control placeholder="(000) 000-0000" type="tel" name="viber" onChange={(e) => { setContactInfo(e.target) }} />
                                        <label className='sm-label'>Viber ဖွင့်လှစ်ထားသည့်ဖုန်းနံပါတ်အားထည့်သွင်းပါ။</label>
                                    </Col>
                                </Form.Group>
                            }
                            {this.state.contact.includes('messenger') === true &&
                                <Form.Group className="mb-4 row">
                                    <Col md={12}>
                                        <Form.Label className='mb-2'>Messenger <strong className='mandatorytxt'>*</strong></Form.Label>
                                        <Form.Control placeholder="https://m.me/" type="url" name="messenger" onChange={(e) => { setContactInfo(e.target) }} />
                                        <label className='sm-label'>Messenger Url အားထည့်သွင်းပါ။</label>
                                    </Col>
                                </Form.Group>
                            }
                            <Form.Group className={`row ${this.state.isMobile === false ? "mb-4" : "mb-2"}`}>
                                <Col md={12} className="d-grid gap-2">
                                    <Button variant="primary" className="mt-3 btn-lg" size="lg" onClick={checkValidation}> Submit </Button>
                                    <DataModal show={this.state.showModal} onHide={() => this.setState({ showModal: false })} data={this.state} />
                                </Col>
                            </Form.Group>
                        </Form>
                        <Footer />
                    </Container>
                ) : (
                    <LoadingSceen />
                )}
            </>
        )
    }
}

function DataModal(props) {
    const submitData = () => {
        submitOverseaData(props.data).then((res) => {
            console.log(res)
            if (res.code === 200) {
                localStorage.setItem("code", res.code);
                localStorage.setItem("message", res.message);
            } else {
                localStorage.setItem("code", res.code);
                localStorage.setItem("message", res.message);
            }
            window.location = '/success?overseacomplaint';
        });
    }
    return (
        <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    အချက်အလက်များအားစစ်ဆေးပါ။
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group className="mb-4 row">
                    <Col md={4}>
                        <Form.Label className='mb-2'> <strong> နိုင်ငံ </strong></Form.Label>
                    </Col>
                    <Col md={8}>
                        <Form.Label className='mb-2'> : {props.data.country} </Form.Label>
                    </Col>
                </Form.Group>
                <Form.Group className="mb-4 row">
                    <Col md={4}>
                        <Form.Label className='mb-2'> <strong> အမည် </strong></Form.Label>
                    </Col>
                    <Col md={8}>
                        <Form.Label className='mb-2'> : {props.data.name} </Form.Label>
                    </Col>
                </Form.Group>
                <Form.Group className="mb-4 row">
                    <Col md={4}>
                        <Form.Label className='mb-2'> <strong> Passport / CI နံပါတ် </strong></Form.Label>
                    </Col>
                    <Col md={8}>
                        <Form.Label className='mb-2'> : {props.data.passport} </Form.Label>
                    </Col>
                </Form.Group>
                <Form.Group className="mb-4 row">
                    <Col md={4}>
                        <Form.Label className='mb-2'> <strong> စက်ရုံ (သို့) လုပ်ငန်း အမည် </strong></Form.Label>
                    </Col>
                    <Col md={8}>
                        <Form.Label className='mb-2'> : {props.data.factoryname} </Form.Label>
                    </Col>
                </Form.Group>
                <Form.Group className="mb-4 row">
                    <Col md={4}>
                        <Form.Label className='mb-2'> <strong> စက်ရုံ (သို့) လုပ်ငန်း တည်နေရာ</strong></Form.Label>
                    </Col>
                    <Col md={8}>
                        <Form.Label className='mb-2'> : {props.data.factoryaddress} </Form.Label>
                    </Col>
                </Form.Group>
                <Form.Group className="mb-4 row">
                    <Col md={4}>
                        <Form.Label className='mb-2'> <strong> တိုင်ကြားလိုသည့် အကြောင်းအရာ </strong></Form.Label>
                    </Col>
                    <Col md={8}>
                        <Form.Label className='mb-2'> : {props.data.title} </Form.Label>
                    </Col>
                </Form.Group>
                <Form.Group className="mb-4 row">
                    <Col md={4}>
                        <Form.Label className='mb-2'> <strong> အမှုဖြစ်စဉ် </strong></Form.Label>
                    </Col>
                    <Col md={8}>
                        <Form.Label className='mb-2'> : {props.data.description} </Form.Label>
                    </Col>
                </Form.Group>
                <Form.Group className="mb-4 row">
                    <Col md={4}>
                        <Form.Label className='mb-2'> <strong> ဆက်သွယ်ရန် လိပ်စာ </strong></Form.Label>
                    </Col>
                    <Col md={8}>
                        {props.data.contactInfo.phone !== null &&
                            <Form.Label className='mb-2 d-block'> : {props.data.contactInfo.phone} </Form.Label>
                        }
                        {props.data.contactInfo.viber !== null &&
                            <Form.Label className='mb-2 d-block'> : {props.data.contactInfo.viber} </Form.Label>
                        }
                        {props.data.contactInfo.line !== null &&
                            <Form.Label className='mb-2 d-block'> : {props.data.contactInfo.line} </Form.Label>
                        }
                        {props.data.contactInfo.messenger !== null &&
                            <Form.Label className='mb-2 d-block'> : {props.data.contactInfo.messenger} </Form.Label>
                        }
                    </Col>
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.onHide}>Cancel</Button>
                <Button onClick={submitData}>Confirm</Button>
            </Modal.Footer>
        </Modal>
    );
}