import React, { Component } from 'react';
import './Footer.css'

export default class Footer extends Component {
    render() {
        return (
            <footer className='text-center p-2'>
                <p>Copyright © 2022 - 2024 <a href='https://mol.nugmyanmar.org/' className='link App-link'>Ministry of Labour</a> </p>
                <p>National Unity Government of the Republic of the Union of Myanmar.</p>
            </footer>
        );
    }
} 