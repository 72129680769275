import React, { Component } from 'react';
import { Form, Row, Col, Button, Modal } from 'react-bootstrap';
import LoadingSceen from '../Loading/Loading';

export default class SeafarerComplaint extends Component {
    state = {
        "loading": true,
        "showModal": true,
        "option": "",
        "other": "",
    }
    componentDidMount() {
        setTimeout(() => this.setState({ loading: false }), 500);
    }
    render() {
        const goBackHome = () => {
            window.location = '/showHome';
        };
        const radioHandler = (value) => {
            this.setState({ option: value })
        };
        const goToForm = () => {
            if (this.state.option === "အခြား") {
                localStorage.setItem("help", this.state.other);
            } else {
                localStorage.setItem("help", this.state.option);
            }
            if (this.state.option === "သေဆုံးမှု") {
                window.location = '/seafarerDeath';
            } else {
                window.location = '/seafarer';
            }
        };
        return (
            <>
                {
                    this.state.loading === false ? (
                        <Modal size="lg" show={this.state.showModal} aria-labelledby="contained-modal-title-vcenter" centered >
                            <Modal.Header>
                                <Modal.Title id="contained-modal-title-vcenter">
                                    မည်သည့်အကူအညီလိုအပ်ပါသလဲ။
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form.Group className="row">
                                    <Col md={12}>
                                        <Row>
                                            <Col md={4} className='mb-4 pt-1'>
                                                <Form.Check type="radio" name="option" label="(က) လုပ်ခလစာ" onClick={(e) => radioHandler("လုပ်ခလစာ")} />
                                            </Col>
                                            <Col md={4} className='mb-4 pt-1'>
                                                <Form.Check type="radio" name="option" label="(ခ) နစ်နာကြေး/လျော်ကြေး" onClick={(e) => radioHandler("နစ်နာကြေး/လျော်ကြေး")} />
                                            </Col>
                                            <Col md={4} className='mb-4 pt-1'>
                                                <Form.Check type="radio" name="option" label="(ဂ) အိမ်ပြန်ခရီးစီစဉ်ပေးရန်" onClick={(e) => radioHandler("အိမ်ပြန်ခရီးစီစဉ်ပေးရန်")} />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col md={12}>
                                        <Row>
                                            <Col md={4} className='mb-4 pt-1'>
                                                <Form.Check type="radio" name="option" label="(ဃ) လုပ်ငန်းခွင်ထိခိုက်မှု" onClick={(e) => radioHandler("လုပ်ငန်းခွင်ထိခိုက်မှု")} />
                                            </Col>
                                            <Col md={4} className='mb-4 pt-1'>
                                                <Form.Check type="radio" name="option" label="(င) ကျန်းမာရေးစောင့်ရှောက်မှု" onClick={(e) => radioHandler("ကျန်းမာရေးစောင့်ရှောက်မှု")} />
                                            </Col>
                                            <Col md={4} className='mb-4 pt-1'>
                                                <Form.Check type="radio" name="option" label="(စ) သေဆုံးမှု" onClick={(e) => radioHandler("သေဆုံးမှု")} />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col md={12}>
                                        <Row>
                                            <Col md={4} className='mb-4 pt-1'>
                                                <Form.Check type="radio" name="option" label="(ဆ) မတရားအလုပ်ထုတ်ခံရမှု" onClick={(e) => radioHandler("မတရားအလုပ်ထုတ်ခံရမှု")} />
                                            </Col>
                                            <Col md={4} className='mb-4 pt-1'>
                                                <Form.Check type="radio" name="option" label="(ဇ) ခွဲခြားဆက်ဆံခံရခြင်း" onClick={(e) => radioHandler("ခွဲခြားဆက်ဆံခံရခြင်း")} />
                                            </Col>
                                            <Col md={4} className='mb-4 pt-1'>
                                                <Form.Check type="radio" name="option" label="(ဈ) ခိုက်ရန်ဖြစ်ပွားခြင်း" onClick={(e) => radioHandler("ခိုက်ရန်ဖြစ်ပွားခြင်း")} />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col md={12}>
                                        <Row>
                                            <Col md={8} className='mb-4 pt-1'>
                                                <Form.Check type="radio" name="option" label="(ည) လုပ်ငန်းခွင်အခြေအနေလိုအပ်ချက်" onClick={(e) => radioHandler("လုပ်ငန်းခွင်အခြေအနေလိုအပ်ချက်")} />
                                            </Col>
                                        </Row>
                                    </Col>

                                    <Col md={12}>
                                        <Row>
                                            <Col md={4} className='pt-1 mb-4'>
                                                <Form.Check type="radio" name="option" label="(ဋ) အခြား " onClick={(e) => radioHandler("အခြား")} />
                                            </Col>
                                            {this.state.option === "အခြား" &&
                                                <Col md={8} className="mb-2">
                                                    <Form.Control placeholder="အကြောင်းအရာ" type="text" name="other" onChange={(e) => { this.setState({ other: e.target.value }) }} />
                                                </Col>
                                            }
                                        </Row>
                                    </Col>
                                </Form.Group>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={goBackHome}>Cancel</Button>
                                <Button onClick={goToForm}>Confirm</Button>
                            </Modal.Footer>
                        </Modal>
                    ) : (
                        <LoadingSceen />
                    )

                }
            </>
        );
    }
}