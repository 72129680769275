import { initializeApp } from "firebase/app";
import { getFirestore, addDoc, collection, doc } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { encode } from 'base-64';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAPfaNA2D8BBI14IPfC1lcvUvUzPbUgha4",
  authDomain: "concern-mol.firebaseapp.com",
  projectId: "concern-mol",
  storageBucket: "concern-mol.appspot.com",
  messagingSenderId: "345371520421",
  appId: "1:345371520421:web:e64085a83f35a5385fcc4c",
  measurementId: "G-68E65F6YZG"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
export const storage = getStorage(app);

async function sendDatatoLocalComplaintBotViaWebhook(message) {
  const url = 'https://chat.googleapis.com/v1/spaces/AAAA88H8mlg/messages?key=AIzaSyDdI0hCZtE6vySjMm-WEfRq3CPzqKqqsHI&token=XoP9Q8X7lmXvjR_OPTcAtyyyrdKoRdHsnvskO5bbTG4';
  const res = await fetch(url, {
    method: "POST",
    headers: { "Content-Type": "application/json; charset=UTF-8" },
    body: JSON.stringify({ text: message })
  });
  return await res.json();
}

async function sendDatatoOverseaComplaintBotViaWebhook(message) {
  const url = 'https://chat.googleapis.com/v1/spaces/AAAAeRJ7o_8/messages?key=AIzaSyDdI0hCZtE6vySjMm-WEfRq3CPzqKqqsHI&token=IvqsF3y5aWLmG_S3Hu9qE6l2HrzxV1YK2ANW1kHZbp0';
  const res = await fetch(url, {
    method: "POST",
    headers: { "Content-Type": "application/json; charset=UTF-8" },
    body: JSON.stringify({ text: message })
  });
  return await res.json();
}

export const submitOverseaData = async (data) => {
  console.log(data)
  // const utf8 = require('utf8');
  // if (data.contactInfo.phone !== null) {
  //   data.contactInfo.phone = encode(utf8.encode(data.contactInfo.phone));
  // }
  // if (data.contactInfo.viber !== null) {
  //   data.contactInfo.viber = encode(utf8.encode(data.contactInfo.viber));
  // }
  // if (data.contactInfo.line !== null) {
  //   data.contactInfo.line = encode(utf8.encode(data.contactInfo.line));
  // }
  // if (data.contactInfo.messenger !== null) {
  //   data.contactInfo.messenger = encode(utf8.encode(data.contactInfo.messenger));
  // }
  try {
    // await addDoc(collection(db, "oversea"), {
    //   "country": encode(utf8.encode(data.country)),
    //   "name": encode(utf8.encode(data.name)),
    //   "passport": encode(utf8.encode(data.passport)),
    //   "factoryname": encode(utf8.encode(data.factoryname)),
    //   "factoryaddress": encode(utf8.encode(data.factoryaddress)),
    //   "title": encode(utf8.encode(data.title)),
    //   "description": encode(utf8.encode(data.description)),
    //   "contact": data.contactInfo,
    //   "timestamp": data.timestamp,
    // });
    // window.Email.send({
    //   SecureToken: "941a6a79-d081-4243-9ef4-83f64b58ccb8",
    //   To: 'complaint@molmyanmar.org',
    //   From: "donotreply@system.molmyanmar.org",
    //   Subject: "New Oversea Complaint",
    //   Body: `New Oversea Complaint from ${data.name} in ${data.country} has been submitted.` + `\n` + `Details as shown below:` + `\n` + `Passport: ${data.passport}` + `\n` + `Factory Name: ${data.factoryname}` + `\n` + `Factory Address: ${data.factoryaddress}` + `\n` + `Title: ${data.title}` + `\n` + `Description: ${data.description}` + `\n` + `Contact Info: Phone: ${data.contactInfo.phone}, Viber: ${data.contactInfo.viber}, Line: ${data.contactInfo.line}, Messenger: ${data.contactInfo.messenger}` + `\n` + `Timestamp: ${data.timestamp}`
    // }).then(
    //   console.log("Success!")
    // );
    const message = `New Oversea Complaint from ${data.name} in ${data.country} has been submitted.` + `\n` + `\n`
      + `Details as shown below:` + `\n` + `\n`
      + `Personal Info:` + `\n`
      + `Name: ${data.name}` + `\n`
      + `Passport: ${data.passport}` + `\n` + `\n`
      + `Factory Info:` + `\n`
      + `Factory Name: ${data.factoryname}` + `\n`
      + `Factory Address: ${data.factoryaddress}` + `\n` + `\n`
      + `Complaint Info:` + `\n`
      + `Title: ${data.title}` + `\n`
      + `Description: ${data.description}` + `\n` + `\n`
      + `Contact Info: Phone: ${data.contactInfo.phone}, Viber: ${data.contactInfo.viber}, Line: ${data.contactInfo.line}, Messenger: ${data.contactInfo.messenger}` + `\n` + `\n`
      + `Timestamp: ${data.timestamp} Myanmar Standard Time` + `\n` + `\n`
      + `Please check the details and take necessary actions.` + `\n`
      + `Thank you!`;
    sendDatatoOverseaComplaintBotViaWebhook(message).then(res => console.log(res));
    return { code: 200, message: "Successfully Submitted!" };
  } catch (err) {
    console.error(err.message);
    return { code: 400, message: err.message };
  }
};

export const submitLocalData = async (data) => {
  console.log(data)
  const utf8 = require('utf8');
  if (data.email === null) {
    try {
      // await addDoc(collection(db, "local"), {
      //   "name": encode(utf8.encode(data.name)),
      //   "gender": encode(utf8.encode(data.gender)),
      //   "nrc": encode(utf8.encode(data.nrc)),
      //   "position": encode(utf8.encode(data.position)),
      //   "factoryname": encode(utf8.encode(data.factoryname)),
      //   "factoryaddress": encode(utf8.encode(data.address)),
      //   "telegramnum": encode(utf8.encode(data.telegram)),
      //   "email": encode(utf8.encode("null")),
      //   "title": encode(utf8.encode(data.cmtitle)),
      //   "description": encode(utf8.encode(data.description)),
      //   "timestamp": data.timestamp,
      // });
      const message = `New Local Complaint from ${data.name} has been submitted.` + `\n` + `\n`
        + `Details as shown below:` + `\n` + `\n`
        + `Personal Info:` + `\n`
        + `Name: ${data.name}` + `\n`
        + `NRC: ${data.nrc}` + `\n`
        + `Position: ${data.position}` + `\n`
        + `Gender: ${data.gender}` + `\n` + `\n`
        + `Factory Info:` + `\n`
        + `Factory Name: ${data.factoryname}` + `\n`
        + `Factory Address: ${data.address}` + `\n` + `\n`
        + `Complaint Info:` + `\n`
        + `Title: ${data.cmtitle}` + `\n`
        + `Description: ${data.description}` + `\n` + `\n`
        + `Contact Info: Telegram: ${data.telegram}` + `\n` + `\n`
        + `Timestamp: ${data.timestamp} Myanmar Standard Time` + `\n` + `\n`
        + `Please check the details and take necessary actions.` + `\n`
        + `Thank you!`;
      sendDatatoLocalComplaintBotViaWebhook(message).then(res => console.log(res));
      return { code: 200, message: "Successfully Submitted!" };
    } catch (err) {
      console.error(err.message);
      return { code: 400, message: err.message };
    }
  } else {
    try {
      // await addDoc(collection(db, "local"), {
      //   "name": encode(utf8.encode(data.name)),
      //   "gender": encode(utf8.encode(data.gender)),
      //   "nrc": encode(utf8.encode(data.nrc)),
      //   "position": encode(utf8.encode(data.position)),
      //   "factoryname": encode(utf8.encode(data.factoryname)),
      //   "factoryaddress": encode(utf8.encode(data.address)),
      //   "telegramnum": encode(utf8.encode(data.telegram)),
      //   "email": encode(utf8.encode(data.email)),
      //   "title": encode(utf8.encode(data.cmtitle)),
      //   "description": encode(utf8.encode(data.description)),
      //   "timestamp": data.timestamp,
      // });
      const message = `New Local Complaint from ${data.name} has been submitted.` + `\n` + `\n`
        + `Details as shown below:` + `\n` + `\n`
        + `Personal Info:` + `\n`
        + `Name: ${data.name}` + `\n`
        + `NRC: ${data.nrc}` + `\n`
        + `Position: ${data.position}` + `\n`
        + `Gender: ${data.gender}` + `\n` + `\n`
        + `Factory Info:` + `\n`
        + `Factory Name: ${data.factoryname}` + `\n`
        + `Factory Address: ${data.address}` + `\n` + `\n`
        + `Complaint Info:` + `\n`
        + `Title: ${data.cmtitle}` + `\n`
        + `Description: ${data.description}` + `\n` + `\n`
        + `Contact Info: Telegram: ${data.telegram}, Email: ${data.email}` + `\n` + `\n`
        + `Timestamp: ${data.timestamp} Myanmar Standard Time` + `\n` + `\n`
        + `Please check the details and take necessary actions.` + `\n`
        + `Thank you!`;
      sendDatatoLocalComplaintBotViaWebhook(message).then(res => console.log(res));
      return { code: 200, message: "Successfully Submitted!" };
    } catch (err) {
      console.error(err.message);
      return { code: 400, message: err.message };
    }
  }

};

export const submitSeafarerData = async (data) => {
  console.log(data)
  const utf8 = require('utf8');
  try {
    await addDoc(collection(db, "seafater"), {
      "title": encode(utf8.encode(data.help)),
      "name": encode(utf8.encode(data.name)),
      "nationality": encode(utf8.encode(data.nationality)),
      "passport": encode(utf8.encode(data.passport)),
      "cdc": encode(utf8.encode(data.cdcNo)),
      "company": encode(utf8.encode(data.company)),
      "position": encode(utf8.encode(data.position)),
      "ship": encode(utf8.encode(data.ship)),
      "shipType": encode(utf8.encode(data.shipType)),
      "shipFlag": encode(utf8.encode(data.shipFlag)),
      "imo": encode(utf8.encode(data.imoNo)),
      "current": encode(utf8.encode(data.currentLoc)),
      "next": encode(utf8.encode(data.nextLoc)),
      "estimate": encode(utf8.encode(data.estTime)),
      "seaman": encode(utf8.encode(data.seamanCount)),
      "ton": encode(utf8.encode(data.tonCount)),
      "owner": encode(utf8.encode(data.ownerName)),
      "dpaName": encode(utf8.encode(data.dpaName)),
      "dpaPhone": encode(utf8.encode(data.dpaPhone)),
      "dpaEmail": encode(utf8.encode(data.dpaEmail)),
      "description": encode(utf8.encode(data.description)),
      "prostartDate": encode(utf8.encode(data.problemstartDate)),
      "goBackDate": encode(utf8.encode(data.goBackDate)),
      "startDate": encode(utf8.encode(data.startDate)),
      "contractEndDate": encode(utf8.encode(data.contractEndDate)),
      "otherDes": encode(utf8.encode(data.otherDes)),
      "contact": encode(utf8.encode(data.contact)),
      "desSame": encode(utf8.encode(data.desSame)),
      "contactNumber": encode(utf8.encode(data.contactNumber)),
      "email": encode(utf8.encode(data.email)),
      "timestamp": data.timestamp,
    });
    return { code: 200, message: "Successfully Submitted!" };
  } catch (err) {
    console.error(err.message);
    return { code: 400, message: err.message };
  }
};

export const submitSeafarerDeathData = async (data) => {
  console.log(data)
  const emailOption = data.email === null ? null : encode(utf8.encode(data.email));
  const utf8 = require('utf8');
  try {
    await addDoc(collection(db, "seadeath"), {
      "title": encode(utf8.encode(data.help)),
      "name": encode(utf8.encode(data.name)),
      "cdc": encode(utf8.encode(data.cdcNo)),
      "position": encode(utf8.encode(data.position)),
      "imo": encode(utf8.encode(data.imoNo)),
      "ship": encode(utf8.encode(data.ship)),
      "nok": data.nokImg,
      "contractImg": data.contractImg,
      "deadNoteImg": data.deadNoteImg,
      "notebyCaptainImg": data.notebyCaptainImg,
      "description": encode(utf8.encode(data.description)),
      "contact": encode(utf8.encode(data.contact)),
      "contactNo": encode(utf8.encode(data.contactNumber)),
      "email": emailOption,
      "timestamp": data.timestamp,
    });
    return { code: 200, message: "Successfully Submitted!" };
  } catch (err) {
    console.error(err.message);
    return { code: 400, message: err.message };
  }
};