import React from 'react';
import { Container, Image } from "react-bootstrap";
import loadingGif from "../../Images/molconcern-loadingicn.gif";
import './Loading.css';

export default function LoadingSceen() {
    return (
        <Container fluid className='loading'>
            <Image src={loadingGif} alt={loadingGif} width='150px' />
        </Container>
    )
}