import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Image, Button, Alert, Offcanvas, Badge, Modal } from 'react-bootstrap';
import { FaMailBulk, FaTelegram, FaViber, FaWpforms, FaPhoneAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import './App.css';
import complaintlogo from "./Images/logo.svg";
import agreementicn from "./Images/agreement.png";
import TopBar from "./Components/TopBar/TopBar";
import Footer from './Components/Footer/Footer';
import cardlogo from "./Images/molcomplaint_logo_.png";
import { FaPhone, FaLine } from "react-icons/fa6";
import { BsSignal } from "react-icons/bs";

function OffCanvas({ name, ...props }) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    });
  }, []);

  return (
    <>
      {showButton && (
        <Button variant="light" onClick={handleShow} className="me-2 btnsolve">
          {name} <Badge bg="primary">New</Badge>
        </Button>
      )}
      <Offcanvas show={show} onHide={handleClose} {...props}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>ဖြေရှင်းပေးနေမှုအခြေအနေများ</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          Some text as placeholder. In real life you can have the elements you
          have chosen. Like, text, images, lists, etc.
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

function App() {
  var formattedBody = "\n\nအမည် - \nပြန်လည်ဆက်သွယ်နိုင်မည့် တယ်လီဂရန်ဖုန်းနံပါတ် - \nThird Line - \n\n\nအခြားပြောကြားလိုသည်များအားထည့်သွင်းပါ - \n\n\n\n";
  var localformattedBody = "\n\nအခွင့်အရေးချိုးဖောက်မှုများကိုအကောင်းမွန်ဆုံးဖြေရှင်းပေးနိုင်ရန်အတွက် ဖော်ပြပါအချက်အလက်များပြည့်စုံစွာဖြည့်သွင်းပေးရန်လိုအပ်ပါသည်။\n\nသို့ဖြစ်ပါ၍တိုင်ကြားမှုအတွက်အချက်အလက်များကိုအောက်တွင်ဖော်ပြထားသည့်သတ်မှတ်နေရာများတွင်ဖြည့်သွင်းပေးပါ။\n\n\nအမည် - \nကျား၊ မ - \nမှတ်ပုံတင်အမှတ် - \nရာထူး / အလုပ်အကိုင် - \nစက်ရုံ (သို့) လုပ်ငန်း အမည် - \nစက်ရုံ (သို့) လုပ်ငန်း တည်နေရာ (လိပ်စာပြည့်စုံစွာထည့်သွင်းပါ) - \nပြန်လည်ဆက်သွယ်ရမည့် Telegram ဖုန်းနံပါတ် - \nတိုင်ကြားလိုသည့် အကြောင်းအရာ - \nအမှုဖြစ်စဉ် - \n\n\n\n";
  var overseaformattedBody = "\n\nအခွင့်အရေးချိုးဖောက်မှုများကိုအကောင်းမွန်ဆုံးဖြေရှင်းပေးနိုင်ရန်အတွက် ဖော်ပြပါအချက်အလက်များပြည့်စုံစွာဖြည့်သွင်းပေးရန်လိုအပ်ပါသည်။\n\nသို့ဖြစ်ပါ၍တိုင်ကြားမှုအတွက်အချက်အလက်များကိုအောက်တွင်ဖော်ပြထားသည့်သတ်မှတ်နေရာများတွင်ဖြည့်သွင်းပေးပါ။\n\n\nလက်ရှိနိုင်ငံအားဖော်ပြပါ - \nအမည် - \nPassport / CI နံပါတ် - \nစက်ရုံ (သို့) လုပ်ငန်း အမည် - \nစက်ရုံ (သို့) လုပ်ငန်း တည်နေရာ (လိပ်စာပြည့်စုံစွာထည့်သွင်းပါ) - \nပြန်လည်ဆက်သွယ်ရမည့် ဖုန်းနံပါတ်(သို့)Line(သို့)Viber(သို့)Messenger URL - \nတိုင်ကြားလိုသည့် အကြောင်းအရာ - \nအမှုဖြစ်စဉ် - \n\n\n\n";
  const [showUI, setShowUI] = useState(false);
  const [showPhoneModel, setShowPhoneModel] = useState(false);
  const [showPhoneThaiModel, setShowPhoneThaiModel] = useState(false);
  const [showPhoneMalayModel, setShowPhoneMalayModel] = useState(false);

  const url = new URL(window.location.href);
  const getVal = url.pathname.includes("showHome");

  const openPhoneModel = () => {
    setShowPhoneModel(true);
  }

  const settingPhoneThaiModel = () => {
    setShowPhoneModel(false);
    setShowPhoneThaiModel(true);
  }

  const settingPhoneMalayModel = () => {
    setShowPhoneModel(false);
    setShowPhoneMalayModel(true);
  }

  console.log(getVal)
  const navigate = useNavigate();
  const goTolocal = () => {
    navigate('/local')
  }
  const goToOversea = () => {
    navigate('/oversea')
  }
  const goToSeafarer = () => {
    navigate('/seafarerOpt')
  }
  const goToMaintain = () => {
    navigate('/maintain')
  }
  const showHomeUI = () => {
    window.scrollTo(0, 0);
    setShowUI(true);
  }
  const goToTermofUse = () => {
    navigate('/termofuse')
  }
  return (
    <>
      <Modal show={showPhoneModel} onHide={() => setShowPhoneModel(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>နိုင်ငံရွေးချယ်ပါ</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col md={12} className='text-center'>
                {/* <h5>ဖုန်းနံပါတ်များ</h5> */}
                <Button variant="btn btn-outline-primary mb-3 mt-2" className="phonebtn" onClick={() => settingPhoneThaiModel()}>
                  {/* <FaPhoneAlt /> */}
                  <label className='labelcenter'>ထိုင်းနိုင်ငံ</label>
                </Button>
                <Button variant="btn btn-outline-primary mb-3" className="phonebtn" onClick={() => settingPhoneMalayModel()}>
                  {/* <FaPhoneAlt /> */}
                  <label className='labelcenter'>မလေးရှားနိုင်ငံ</label>
                </Button>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
      <Modal show={showPhoneThaiModel} onHide={() => setShowPhoneThaiModel(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>ထိုင်းနိုင်ငံ ဖုန်းနံပါတ်များ</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col md={12} className='text-center'>
                <Button size='lg' variant="btn btn-outline-primary mb-3 mt-2" className="phonebtn" onClick={() => window.location = 'tel:+66953950769'}>
                  {/* <FaPhoneAlt /> */}
                  <label className='labelcenter'>Hotline 1</label>
                </Button>
                <Button size='lg' variant="btn btn-outline-primary mb-3" className="phonebtn" onClick={() => window.location = 'tel:+66969801346'}>
                  {/* <FaPhoneAlt /> */}
                  <label className='labelcenter'>Hotline 2</label>
                </Button>
                <Button size='lg' variant="btn btn-outline-primary mb-3" className="phonebtn" onClick={() => window.location = 'tel:+66969801067'}>
                  {/* <FaPhoneAlt /> */}
                  <label className='labelcenter'>Hotline 3</label>
                </Button>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
      <Modal show={showPhoneMalayModel} onHide={() => setShowPhoneMalayModel(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>မလေးရှားနိုင်ငံ ဖုန်းနံပါတ်များ</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col md={12} className='text-center'>
                <Button variant="btn btn-outline-primary mb-3 mt-2" className="phonebtn" onClick={() => window.location = 'tel:+601116754215'}>
                  {/* <FaPhoneAlt /> */}
                  <label className='labelcenter'>Hotline 1</label>
                </Button>
                <Button variant="btn btn-outline-primary mb-3" className="phonebtn" onClick={() => window.location = 'tel:+601162167739'}>
                  {/* <FaPhoneAlt /> */}
                  <label className='labelcenter'>Hotline 2</label>
                </Button>
                <Button variant="btn btn-outline-primary mb-3" className="phonebtn" onClick={() => window.location = 'tel:+601126711990'}>
                  {/* <FaPhoneAlt /> */}
                  <label className='labelcenter'>Hotline 3</label>
                </Button>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
      <Container className='bg p-0' fluid>
        <TopBar />
        <marquee className="news-content">
          ဝန်ကြီးဌာနအနေဖြင့် တော်လှန်ရေးကာလအတွင်း ညှိနှိုင်းဖြေရှင်းဆောင်ရွက်ပေးနိုင်သည့် တိုင်ကြားမှုများကို အချိန်နှင့် တပြေးညီ ဖြေရှင်းဆောင်ရွက်ပေးလျက်ရှိပြီး အရေးယူမှုပြုလုပ်ရန်လိုအပ်သည့် အလုပ်သမားအခွင့်အရေးချိုးဖောက်မှုများအား စနစ်တကျ မှတ်တမ်းထားရှိ၍ ပြည်သူ့အစိုးရ တာဝန်ယူချိန်တွင် စီစစ်အရေးယူသွားမည်ဖြစ်ကြောင်း အသိပေးအပ်ပါသည်။
        </marquee>
        {showUI === true || getVal === true ? (
          <Container className='animate'>
            {/* <OffCanvas placement='end' name="ဖြေရှင်းပေးနေမှုအခြေအနေများ" /> */}
            <Container className='text-center'>
              <div className='d-block mx-auto'>
                <Image className='img-fluid headerlogosize' src={complaintlogo} alt={complaintlogo} />
                <h3>တိုင်ကြားရေးယန္တရား</h3>
              </div>
            </Container>
            <Container>
              <Row>
                <Col md={5} className='shadow p-4 my-5 bg-body rounded text-center'>
                  <h2 className='ctitle lh-lg'>ပြည်တွင်းအလုပ်သမားတိုင်ကြားရေးယန္တရား</h2>
                  <p>ပြည်တွင်းအလုပ်သမားရေးရာကိစ္စရပ်များအားတိုင်ကြားနိုင်ရန်</p>
                  <Button variant="btn btn-outline-primary" className="agreebtn" onClick={goTolocal}>
                    <FaWpforms />
                    <label className='labelcenter'>ဖောင်ဖြည့်သွင်း တိုင်ကြားမည်</label>
                  </Button>
                  <Button variant="btn btn-outline-primary" className="agreebtn" onClick={() => window.location = 'mailto:complaint@molmyanmar.org?subject=ပြည်တွင်းတိုင်ကြားရေးယန္တရား&body=(မဖြစ်မနေထည့်သွင်းရမည့်အချက်အလက်များ - အမည်၊ ကျား/ မ၊ မှတ်ပုံတင်အမှတ်၊ ရာထူး / အလုပ်အကိုင်၊ စက်ရုံ (သို့) လုပ်ငန်း အမည်၊ စက်ရုံ (သို့) လုပ်ငန်း တည်နေရာ (လိပ်စာပြည့်စုံစွာထည့်သွင်းပါ)၊ ပြန်လည်ဆက်သွယ်ရမည့် Telegram ဖုန်းနံပါတ်၊ တိုင်ကြားလိုသည့် အကြောင်းအရာ နှင့် အမှုဖြစ်စဉ်)' + encodeURIComponent(localformattedBody)}>
                    <FaMailBulk />
                    <label className='labelcenter'>အီးမေးလ်မှ တဆင့်တိုင်ကြားမည်</label>
                  </Button>
                  <div className="divider div-transparent div-arrow-down"></div>
                  <p>အခြားသိရှိလိုသည်များအား မေးမြန်းရန်</p>
                  <a className='contactBox' href="https://go.nugmyanmar.org/a982bd36" target={"_blank"}><FaTelegram /></a>
                </Col>
                <Col md={2} className="d-flex align-items-center">
                  <div className="astrodivider"><div className="astrodividermask"></div><span><i>&#10038;</i></span></div>
                </Col>
                <Col md={5} className='shadow p-4fornext my-5 bg-body rounded text-center'>
                  <h2 className='ctitle lh-lg'>ပြည်ပအလုပ်သမားတိုင်ကြားရေးယန္တရား</h2>
                  <p>ပြည်ပအလုပ်သမားရေးရာကိစ္စရပ်များအားတိုင်ကြားနိုင်ရန်</p>
                  <Button variant="btn btn-outline-primary" className="agreebtn" onClick={goToOversea}>
                    <FaWpforms />
                    <label className='labelcenter'>ဖောင်ဖြည့်သွင်း တိုင်ကြားမည်</label>
                  </Button>
                  <Button variant="btn btn-outline-primary" className="agreebtn" onClick={() => window.location = 'mailto:complaint@molmyanmar.org?subject=ပြည်ပတိုင်ကြားရေးယန္တရား&body=(မဖြစ်မနေထည့်သွင်းရမည့်အချက်အလက်များ - အမည်၊ Passport / CI နံပါတ် စက်ရုံ (သို့) လုပ်ငန်း အမည်၊ စက်ရုံ (သို့) လုပ်ငန်း တည်နေရာ (လိပ်စာပြည့်စုံစွာထည့်သွင်းပါ)၊ ပြန်လည်ဆက်သွယ်ရမည့် ဖုန်းနံပါတ်(သို့)Line(သို့)Viber(သို့)Messenger URL၊ တိုင်ကြားလိုသည့် အကြောင်းအရာ နှင့် အမှုဖြစ်စဉ်)' + encodeURIComponent(overseaformattedBody)}>
                    <FaMailBulk />
                    <label className='labelcenter'>အီးမေးလ်မှ တဆင့်တိုင်ကြားမည်</label>
                  </Button>
                  {/* <Button variant="btn btn-outline-primary mb-1" className="agreebtn" onClick={() => window.location = 'tel:+66969429490'}>
                  <FaPhoneAlt />
                  <label className='labelcenter'>ဖုန်းဖြင့် တိုင်ကြားမည်</label>
                </Button> */}
                  <div className="divider div-transparent div-arrow-down"></div>
                  <p>အခြားတိုင်ကြားနိုင်သည့်နည်းလမ်းများ</p>
                  <a className='contactBox' onClick={settingPhoneThaiModel}><FaPhone /></a>
                  <a className='contactBox' href="https://go.nugmyanmar.org/2a973273" ><BsSignal /></a>
                  <a className='contactBox' href="https://go.nugmyanmar.org/a982bd36" target={"_blank"}><FaTelegram /></a>
                  <a className='contactBox' href="https://go.nugmyanmar.org/0ca7e405" ><FaLine /></a>
                </Col>
              </Row>
              <Row className='shadow p-3 my-5 bg-body rounded row text-center'>
                <Col md={12}>
                  <Col md={12}>
                    <h2 className='ctitle p-2 lh-lg'>သင်္ဘောသားတိုင်ကြားရေးယန္တရား</h2>
                    <p>သင်္ဘောသားရေးရာကိစ္စရပ်များအားတိုင်ကြားနိုင်ရန် <label className='known link fw-bolder' onClick={goToSeafarer}>ဖောင်ဖြည့်သွင်း တိုင်ကြားမည်</label>
                    </p>
                  </Col>
                </Col>
                <Col md={12} className="d-flex align-items-center justify-content-center mt-4 mb-3">
                  <div className="astrodivider"><div className="astrodividermask"></div></div>
                </Col>
                <Col md={12} className='contactBox mb-1'>
                  <h2 className='ptitle lh-lg'>ဖောင်ဖြည့်သွင်းရာတွင် နည်းပညာအခက်အခဲရှိပါက ဆက်သွယ်ရန်</h2>
                  <a href="https://go.nugmyanmar.org/a982bd36" target={"_blank"}><FaTelegram /></a>
                  {/* <a href="viber://add?number=66828260627" ><FaViber /></a> */}
                  <FaMailBulk className='pointer' onClick={() => window.location = 'mailto:it@mol.nugmyanmar.org?subject=တိုင်ကြားရေးယန္တရား&body=အကူအညီရယူလိုမှုအတွက်ယခုနေရာတွင်ဖြည့်သွင်းပေးပါ။' + encodeURIComponent("\n\nအမည်လွှဲ - \nပြန်လည်ဆက်သွယ်နိုင်မည့် တယ်လီဂရန်ဖုန်းနံပါတ် - \nဖြစ်စဉ်အကြောင်းအရာ - \n\n\n\n")} />
                </Col>
              </Row>
            </Container>
          </Container>
        ) : (
          <Container className='min-h-screen p-0' fluid>
            <Row className='notic'>
              <Col lg={6} className='d-flex justify-content-center align-items-center'>
                <Image className='img-fluid' src={cardlogo} alt={cardlogo} />
              </Col>
              <Col lg={6} className='introtxt'>
                <Image className='agreementicnsize agreementicn center' src={agreementicn} alt={"Agreement Icon"} />
                <h2 className='ptitle lh-lg'>ကိုယ်ရေးအချက်အလက်မူဝါဒ သဘောတူညီချက်များ</h2>
                <p className='pcontact tnc lh-lg'>ကိုယ်ရေးအချက်အလက်မူဝါဒ အား‌အသေးစိတ်လေ့လာလိုပါက <a className='link' onClick={goToTermofUse}>Terms of Use</a> တွင်လေ့လာကြည့်ရှုနိုင်ပါသည်။</p><br></br>
                <h2 className='ptitle lh-lg'>အခြားထပ်မံဖြည့်စွက်သည့်မူဝါဒများ</h2>
                <p className='pcontact lh-lg'>တော်လှန်ရေးကာလအတွင်း အလုပ်သမားအခွင့်အရေးချိုးဖောက်ခံရမှုများကို ဖော်ထုတ်၍ လိုအပ်သလို အရေးယူဆောင်ရွက်နိုင်ရန် ယခု အချက်အလက်များကို ကောက်ယူခြင်းဖြစ်ပါသည်။</p>
                <p className='pcontact lh-lg'>အချက်အလက်များ ပေါက်ကြားမှုမရှိစေရေး လိုအပ်သော အချက်အလက် လုံခြုံရေးစနစ်များ စီမံဆောင်ရွက် ထားပါသည်။</p>
                <p className='pcontact lh-lg'>အချက်အလက်များကို အခြားသော မသက်ဆိုင်သည့် မည်သည့်အဖွဲ့အစည်းကိုမှ မျှဝေခြင်းပြုလုပ်မည်မဟုတ်ပါ။ သို့ရာတွင် တိုင်ကြားမှုများဖြေရှင်းရာ၌ လိုအပ်ပါက အခြားသော မိတ်ဖက်အဖွဲ့အစည်းများနှင့် ပူးပေါင်းဆောင်ရွက်သွားမည် ဖြစ်ပါသည်။</p>
                <p className='pcontact lh-lg'>
                  အလုပ်သမားရေးရာတိုင်ကြားမှုကို ဖြေရှင်းနိုင်ရေးအတွက် လိုအပ်သော စုံစမ်းစစ်ဆေးမှုများ ပြုလုပ်ရန် တိုင်ကြားသူထံ ဆက်သွယ်မှုများ ပြုလုပ်သွားမည်ဖြစ်ပြီး ထိုသို့ဆက်သွယ်ရာတွင်လည်း Ministry of Labour | NUG ၏ Hot Line နံပါတ်များဖြင့်သော်လည်းကောင်း Messaging Platforms များဖြစ်သည့် Signal, Line နှင့် Telegram များဖြင့်သော်လည်းကောင်း ဆက်သွယ်သွားမည်ဖြစ်ပါသည်။ သို့ပါ၍ တိုင်ကြားသူအနေဖြင့် ညှိနှိုင်းရေးအဖွဲ့မှ ဆက်သွယ်လာပါက လိုအပ်သည်များ ဖြေကြားပေးပါရန် မေတ္တာရပ်ခံအပ်ပါသည်။
                </p>
                <p className='pcontact lh-lg'>အမျိုးသားညီညွတ်ရေးအစိုးရအနေဖြင့် လိုအပ်သောအရေးယူမှုများပြုလုပ်နိုင်ရန် တော်လှန်ရေးကာလအတွင်း အလုပ်သမားဥပဒေချိုးဖောက်သည့် စက်ရုံ၊ အလုပ်ရုံများကို စနစ်တကျမှတ်တမ်းယူထားမည်ဖြစ်ပြီး ယင်းအချက်အလက်များကို အပြည်ပြည်ဆိုင်ရာ အလုပ်သမားရေးရာအဖွဲ့ချုပ်သို့ အစီရင်ခံတင်ပြခြင်း၊ အမည်ပျက်စာရင်းသွင်းခြင်းတို့ကိုလည်း ဆက်လက်ဆောင်ရွက်သွားမည်ဖြစ်ပါသည်။</p>
                <Row>
                  <Button variant="primary" id='btn-agree-primary' onClick={showHomeUI}>I Agree</Button>
                  {/* <button type="button" className="btn btn-outline-primary" onClick={showHomeUI}>I Agree</button> */}
                </Row>
              </Col>
            </Row>
          </Container>
        )
        }
        <Footer />
      </Container >
    </>
  );

}

export default App;
