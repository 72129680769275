import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Home from './Home.js';
import TermOfUse from './Components/TermOfUse/TermOfUse.js';
import LocalComplaint from './Components/LocalComplaint/LocalComplaint';
import OverseaComplaint from './Components/OverseaComplaint/OverseaComplaint';
import SeafarerOption from './Components/seafarerOption/seafarerOption';
import SeafarerComplaint from './Components/SeafarerComplaint/SeafarerComplaint';
import SeafarerDeath from './Components/SeafarerDeath/SeafarerDeath';
import Maintain from './Components/MaintainPage/Maintain';
import Success from './Components/FormSuccess/FormSuccess';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<App />} />
        <Route path="/home" element={<Home />} />
        <Route path="/termofuse" element={<TermOfUse />} />
        <Route path='/local' element={<LocalComplaint />} />
        <Route path='/oversea' element={<OverseaComplaint />} />
        <Route path='/seafarerOpt' element={<SeafarerOption />} />
        <Route path='/seafarer' element={<SeafarerComplaint />} />
        <Route path='/seafarerDeath' element={<SeafarerDeath />} />
        <Route path='/maintain' element={<Maintain />} />
        <Route path='/success' element={<Success />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
