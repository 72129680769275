import React, { Component } from 'react';
import { Container, Row, Col, Navbar, Image, Button, Form, Nav, NavDropdown } from 'react-bootstrap';
import './Home.css';
import complaintlogo from "./Images/mol_complaint_logo.png";

export default class RegForm extends Component {

    render() {
        return (
            <Container fluid className='p-0'>
                <Row className='nugstart' fluid>
                    <div>
                        <img src="https://assets-mol.nugmyanmar.org/images/2021/12/state_logo_flat-01.svg" alt="Myanmar State Emblem" width="30" height="25"></img>
                        <a className='nug hypernug' href="https://nugmyanmar.org" target="_blank" rel="noopener"><span>An Official Website of the National Unity Government</span></a>
                        <a className='nug' data-bs-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
                            How to identify?
                        </a>
                    </div>
                    <div className="collapse topbar2" id="collapseExample">
                        <div>
                            <Row>
                                <Col>
                                    <Col>
                                        <img src="https://d33wubrfki0l68.cloudfront.net/2ec2122b2010672b9cbb6efb5138048d8aff4a09/5d1ab/assets/img/government_building.svg" className="banner-icon"></img>
                                    </Col>
                                    <Col>
                                        <div>
                                            <strong className='subbannertitle'> NUG Official website links usually end with .nugmyanmar.org</strong>
                                            <p className='nugctext'>Ministry of Labour communicate via <strong>.nugmyanmar.org</strong> or <strong>.molmyanmar.org </strong>websites. <a href="https://go.molmyanmar.org/trusted-sites" className="banner-content-text bp-masthead-button-link bp-masthead-button-text" target="_blank" rel="noreferrer">Trusted websites</a></p>
                                        </div>
                                    </Col>
                                </Col>
                                <Col>
                                    <Col>
                                        <img src="https://d33wubrfki0l68.cloudfront.net/aecdf1b88c0edfa72e7fef099f28e93b80c8336a/8c6a1/assets/img/lock.svg" className="banner-icon"></img>
                                    </Col>
                                    <Col>
                                        <dev>
                                            <strong className='subbannertitle'> Secure websites use HTTPS</strong>
                                            <p className='nugctext'>Look for a <strong> lock </strong>(<img src="https://d33wubrfki0l68.cloudfront.net/aecdf1b88c0edfa72e7fef099f28e93b80c8336a/8c6a1/assets/img/lock.svg" className="inline-banner-icon"></img>) or https:// as an added precaution. Share sensitive information only on official, secure websites.</p>
                                        </dev>
                                    </Col>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Row>
                <Navbar className='navbar' sticky="top" expand="lg" fluid>
                    <Container fluid>
                        <Navbar.Brand href="#home">
                            {/* <img
                                alt=""
                                src="\Images\concernlogo.png"
                                width="30"
                                height="30"
                                className="d-inline-block align-top"
                            /> */}
                            <Image className='headerlogo' src={complaintlogo} alt={complaintlogo} />
                            {' '}
                            တိုင်ကြားရေးယန္တရား
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="navbarScroll" />
                        <Navbar.Collapse id="navbarScroll">
                            <Nav
                                className="me-auto my-2 my-lg-0"
                                style={{ maxHeight: '100px' }}
                                navbarScroll
                            >
                                <Nav.Link href="#action1">ပင်မစာမျက်နှာ</Nav.Link>
                                <Nav.Link href="#action2">ဆက်သွယ်နိုင်ရန်</Nav.Link>
                                <NavDropdown title="တိုင်ကြားရေး" id="navbarScrollingDropdown">
                                    <NavDropdown.Item href="/localcomplaint">ပြည်တွင်းတိုင်ကြားရေး</NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item href="#action3">
                                        ပြည်ပတိုင်ကြားရေး
                                    </NavDropdown.Item>
                                </NavDropdown>
                                <Nav.Link href="#" disabled>
                                    သတင်းများ
                                </Nav.Link>
                            </Nav>
                            <Button variant="outline-primary">About Us</Button>
                        </Navbar.Collapse>
                    </Container>
                </Navbar >
                <Container className='homebanner' fluid>

                </Container>
            </Container >
        );
    }
} 