import React, { Component } from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import complaintlogo from "../../Images/logo.svg";
import './Header.css'

export default function Header(props) {

    const goBackHome = () => {
        window.location = '/showHome';
    };
    return (
        <Container fluid className='text-center shadow mb-5 bg-white headermg headerstick'>
            <Container>
                <Row className='px-0 py-3'>
                    <Col md={4}>
                        <Image className='img-fluid mainLogo' src={complaintlogo} alt={complaintlogo} onClick={goBackHome} />
                    </Col>
                    <Col md={6} className="offset-md-2 text-end">
                        <h4 className="my-3 headtitle"> {props.title}</h4>
                        <p className='headsubtitle'>{props.subtitle}</p>
                    </Col>
                </Row>
            </Container>
        </Container>
    );
} 