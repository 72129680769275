import React, { Component } from 'react';
import { Container, Form, Row, Col, Button, Image, Toast, ToastContainer, Modal, Alert } from 'react-bootstrap';
import './LocalComplaint.css';
import InputGroup from 'react-bootstrap/InputGroup';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import { myanmarstate, nrcstatedata, nrctsdata, nrctype } from "../../data";
import { sortString } from "../../utilities";
import concernlogo from "../../Images/concernlogo.png";
import TopBar from "../TopBar/TopBar"
import telegramlogo from "../../Images/telegramlogo.png";
import LoadingSceen from '../Loading/Loading';
import { submitLocalData } from '../../firebase';

const nric_types = nrctype.map((item, index) => {
    return (
        <option key={index} value={item.name}>
            {item.name}
        </option>
    );
});

const states = myanmarstate
    .sort((a, b) => sortString(a.name, b.name))
    .map((item, index) => {
        return (
            <option key={index} value={item.name}>
                {item.name}
            </option>
        );
    });

const statesCityItem = myanmarstate
    .filter((item) => item.name === "ကချင်ပြည်နယ်")
    .map((itemList) => {
        return itemList.townships.map((detail) => {
            return <option value={detail.township_mm}>{detail.township_mm}</option>
        })
    })

const nrcTspItem = nrctsdata
    .filter((item) => item.name === "၁/")
    .map((itemList) => {
        return itemList.list.map((detail) => {
            return <option value={detail.name}>{detail.name}</option>;
        });
    });

const nric_regions = nrcstatedata.map((item, index) => {
    return (
        <option key={index} value={item.name}>
            {item.name}
        </option>
    );
});

export default class LocalComplaint extends Component {
    state = {
        "showModal": false,
        "loading": true,
        "name": null,
        "gender": null,
        "position": null,
        "factoryname": null,
        "telegram": null,
        "email": null,
        "title": null,
        "description": null,
        "timestamp": new Date().toLocaleString('en-US', { timeZone: 'Asia/Yangon' }),
        "success": undefined,
        "error": undefined,
        "complaint": 0,
        "nrcVal": "၁/",
        "selectedState": "ကမတ",
        "nrcType": "(နိုင်)",
        "nrcNumber": null,
        "nrc": null,
        "nrcTsp": nrcTspItem,
        "stateVal": "ကချင်ပြည်နယ်",
        "stateCity": "ဖားကန့်",
        "factoryaddress": null,
        "address": "",
        "mmState": statesCityItem,
        "emptyField": [],
        "isMobile": window.matchMedia("only screen and (max-width: 760px)").matches
    }
    componentDidMount() {
        setTimeout(() => this.setState({ loading: false }), 500);
    }
    render() {
        const handleMobile = () => {
            const getMobile = window.matchMedia("only screen and (max-width: 760px)").matches;
            this.setState({ isMobile: getMobile })
        };
        window.addEventListener('resize', handleMobile);
        const radioHandler = (status) => {
            this.setState({ gender: status });
        };

        const setNrcNumber = (e) => {
            console.log(this.state)
            const nrcNumStr = this.state.nrcVal + this.state.selectedState + this.state.nrcType + e.target.value;
            this.setState({ nrc: nrcNumStr, nrcNumber: e.target.value });
        };

        const onNricRegionSelectChanged = (event) => {
            this.setState({ nrcVal: event.target.value });
            let _nricTownshipItems = nrctsdata
                .filter((item) => item.name === event.target.value)
                .map((itemList) => {
                    return itemList.list.map((detail) => {
                        return <option value={detail.name}>{detail.name}</option>;
                    });
                });
            this.setState({ nrcTsp: _nricTownshipItems });
            const nrcNumStr = event.target.value + _nricTownshipItems[0][0].props.children + this.state.nrcType + this.state.nrcNumber;
            this.setState({ nrc: nrcNumStr });
        };

        const onStateChanged = (event) => {
            this.setState({ selectedState: event.target.value });
            const nrcNumStr = this.state.nrcVal + event.target.value + this.state.nrcType + this.state.nrcNumber;
            this.setState({ nrc: nrcNumStr });
        };

        const onMMStateSelectChanged = (event) => {
            this.setState({ stateVal: event.target.value });
            const factoryaddStr = event.target.value + " ၊ " + this.state.stateCity + " ၊ " + this.state.factoryaddress;
            this.setState({ address: factoryaddStr });
            let _stateCityItems = myanmarstate
                .filter((item) => item.name === event.target.value)
                .map((itemList) => {
                    return itemList.townships.map((detail) => {
                        return <option value={detail.township_mm}>{detail.township_mm}</option>;
                    });
                });
            this.setState({ mmState: _stateCityItems });
        }

        const onMMStateChanged = (event) => {
            this.setState({ stateCity: event.target.value });
            const factoryaddStr = this.state.stateVal + " ၊ " + event.target.value + " ၊ " + this.state.factoryaddress;
            this.setState({ address: factoryaddStr });
        };

        const setMMAddress = (event) => {
            this.setState({ factoryaddress: event.target.value });
            const mmStateStr = this.state.stateVal + " ၊ " + this.state.stateCity + " ၊ " + event.target.value;
            this.setState({ address: mmStateStr });
        };

        const checkValidation = () => {
            if (this.state.name === null) {
                this.setState(prevState => ({
                    emptyField: [...prevState.emptyField, "name"]
                }))
                // let element = document.getElementsByName("name");
                // element[0].focus();
            } else {
                const errArr = this.state.emptyField.filter((item) => item !== "name");
                this.setState({ emptyField: errArr });
            }
            if (this.state.gender === null) {
                this.setState(prevState => ({
                    emptyField: [...prevState.emptyField, "gender"]
                }))
                // let element = document.getElementsByName("gender");
                // element[0].focus();
            } else {
                const errArr = this.state.emptyField.filter((item) => item !== "gender");
                this.setState({ emptyField: errArr });
            }
            if (this.state.nrcNumber === null) {
                this.setState(prevState => ({
                    emptyField: [...prevState.emptyField, "nrcNumber"]
                }))
                // let element = document.getElementsByName("nrcNumber");
                // element[0].focus();
            } else {
                const errArr = this.state.emptyField.filter((item) => item !== "nrcNumber");
                this.setState({ emptyField: errArr });
            }
            if (this.state.position === null) {
                this.setState(prevState => ({
                    emptyField: [...prevState.emptyField, "position"]
                }))
                // let element = document.getElementsByName("position");
                // element[0].focus();
            } else {
                const errArr = this.state.emptyField.filter((item) => item !== "position");
                this.setState({ emptyField: errArr });
            }
            if (this.state.factoryname === null) {
                this.setState(prevState => ({
                    emptyField: [...prevState.emptyField, "factoryname"]
                }))
                // let element = document.getElementsByName("factoryname");
                // element[0].focus();
            } else {
                const errArr = this.state.emptyField.filter((item) => item !== "factoryname");
                this.setState({ emptyField: errArr });
            }
            if (this.state.factoryaddress === null) {
                this.setState(prevState => ({
                    emptyField: [...prevState.emptyField, "factoryaddress"]
                }))
                // let element = document.getElementsByName("factoryaddress");
                // element[0].focus();
            } else {
                const errArr = this.state.emptyField.filter((item) => item !== "factoryaddress");
                this.setState({ emptyField: errArr });
            }
            if (this.state.telegram === null) {
                this.setState(prevState => ({
                    emptyField: [...prevState.emptyField, "telegramnum"]
                }))
                // let element = document.getElementsByName("telegramnum");
                // element[0].focus();
            } else {
                const errArr = this.state.emptyField.filter((item) => item !== "telegramnum");
                this.setState({ emptyField: errArr });
            }
            if (this.state.title === null) {
                this.setState(prevState => ({
                    emptyField: [...prevState.emptyField, "title"]
                }))
                // let element = document.getElementsByName("cmtitle");
                // element[0].focus();
            } else {
                const errArr = this.state.emptyField.filter((item) => item !== "title");
                this.setState({ emptyField: errArr });
            }
            if (this.state.description === null) {
                this.setState(prevState => ({
                    emptyField: [...prevState.emptyField, "description"]
                }))
                // let element = document.getElementsByName("cmbody");
                // element[0].focus();
            } else {
                const errArr = this.state.emptyField.filter((item) => item !== "description");
                this.setState({ emptyField: errArr });
            }

            // if (this.state.name !== null &&
            //     this.state.gender !== null &&
            //     this.state.nrcNumber !== null &&
            //     this.state.position !== null &&
            //     this.state.factoryname !== null &&
            //     this.state.factoryaddress !== null &&
            //     this.state.telegram !== null &&
            //     this.state.title !== null &&
            //     this.state.description !== null) {
            //     this.setState({ showModal: true });
            // }
            if (this.state.name === null &&
                this.state.gender === null &&
                this.state.nrcNumber === null &&
                this.state.position === null &&
                this.state.factoryname === null &&
                this.state.factoryaddress === null &&
                this.state.telegram === null &&
                this.state.title === null &&
                this.state.description === null) {
                let element = document.getElementsByName("name");
                element[0].focus();
            } else if (this.state.gender === null &&
                this.state.nrcNumber === null &&
                this.state.position === null &&
                this.state.factoryname === null &&
                this.state.factoryaddress === null &&
                this.state.telegram === null &&
                this.state.title === null &&
                this.state.description === null) {
                let element = document.getElementsByName("gender");
                element[0].focus();
            } else if (this.state.nrcNumber === null &&
                this.state.position === null &&
                this.state.factoryname === null &&
                this.state.factoryaddress === null &&
                this.state.telegram === null &&
                this.state.title === null &&
                this.state.description === null) {
                let element = document.getElementsByName("nrcNumber");
                element[0].focus();
            } else if (this.state.position === null &&
                this.state.factoryname === null &&
                this.state.factoryaddress === null &&
                this.state.telegram === null &&
                this.state.title === null &&
                this.state.description === null) {
                let element = document.getElementsByName("position");
                element[0].focus();
            } else if (this.state.factoryname === null &&
                this.state.factoryaddress === null &&
                this.state.telegram === null &&
                this.state.title === null &&
                this.state.description === null) {
                let element = document.getElementsByName("factoryname");
                element[0].focus();
            } else if (this.state.factoryaddress === null &&
                this.state.telegram === null &&
                this.state.title === null &&
                this.state.description === null) {
                let element = document.getElementsByName("factoryaddress");
                element[0].focus();
            } else if (this.state.telegram === null &&
                this.state.title === null &&
                this.state.description === null) {
                let element = document.getElementsByName("telegramnum");
                element[0].focus();
            } else if (this.state.title === null &&
                this.state.description === null) {
                let element = document.getElementsByName("cmtitle");
                element[0].focus();
            } else if (this.state.description === null) {
                let element = document.getElementsByName("cmbody");
                element[0].focus();
            } else {
                this.setState({ showModal: true });
            }
        }
        return (
            <>
                {
                    this.state.loading === false ? (
                        <Container fluid className='bg p-0'>
                            <TopBar />
                            <Header title={"ပြည်တွင်းတိုင်ကြားရေးယန္တရား"} subtitle={"ပြည်တွင်းအလုပ်သမားရေးရာကိစ္စရပ်များအားတိုင်ကြားနိုင်ရန်"} />
                            <Form className={`container bg-body rounded ${this.state.isMobile === false ? "shadow col-lg-8 my-5 p-5" : "mx-2.5 p-4 mb-4"}`}>
                                <Form.Group className="row">
                                    <Col md={9} className="mb-4">
                                        <Form.Label className='col-md-6'>အမည် <strong className='mandatorytxt'>*</strong></Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="သင်၏အမည်အားထည့်သွင်းပါ"
                                            name="name"
                                            isInvalid={this.state.emptyField.includes('name')}
                                            onChange={e => this.setState({ name: e.target.value })} />
                                        {/* {this.state.emptyField.includes('name') &&
                                                <Form.Text className='mt-2'> Please Fill your name! </Form.Text>
                                            } */}
                                        <Form.Control.Feedback type='invalid'>
                                            {'အမည်အားမဖြစ်မနေထည့်သွင်းရန်လိုအပ်ပါသည်'}
                                        </Form.Control.Feedback>
                                    </Col>
                                    <Col md={3} className="mb-4">
                                        <Form.Label>ကျား / မ <strong className='mandatorytxt'>*</strong></Form.Label>
                                        <Row>
                                            <Col md={6} className='pt-1'>
                                                <Form.Check
                                                    type="radio"
                                                    name="gender"
                                                    label="ကျား"
                                                    onClick={(e) => radioHandler("ကျား")}
                                                    isInvalid={this.state.emptyField.includes('gender')} />
                                            </Col>
                                            <Col md={6} className='pt-1'>
                                                <Form.Check
                                                    type="radio"
                                                    name="gender"
                                                    label="မ"
                                                    onClick={(e) => radioHandler("မ")}
                                                    isInvalid={this.state.emptyField.includes('gender')} />
                                            </Col>
                                        </Row>
                                        <Form.Control.Feedback type='invalid'>
                                            {'ကျား၊ မ ရွေးချယ်ပေးရန်လိုအပ်ပါသည်'}
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group>
                                <Form.Group className="row">
                                    <Form.Label>မှတ်ပုံတင်အမှတ် <strong className='mandatorytxt'>*</strong></Form.Label>
                                    <Col md={2} className="mb-3">
                                        <div>
                                            <Form.Select value={this.state.nrcVal} onChange={e => onNricRegionSelectChanged(e)}>
                                                {nric_regions}
                                            </Form.Select>
                                        </div>
                                    </Col>
                                    <Col md={3} className="mb-3">
                                        <div>
                                            {this.state.selectedState !== null ? (
                                                <Form.Select value={this.state.selectedState} onChange={e => onStateChanged(e)}>
                                                    {this.state.nrcTsp}
                                                </Form.Select>
                                            ) : (
                                                <Form.Select value={this.state.nrcTsp} multiple={false} onChange={e => onStateChanged(e)}>
                                                    {this.state.nrcTsp}
                                                </Form.Select>
                                            )}
                                        </div>
                                    </Col>
                                    <Col md={2} className="mb-3">
                                        <div>
                                            <Form.Select value={this.state.nrcType} onChange={e => this.setState({ nrcType: e.target.value })}>
                                                {nric_types}
                                            </Form.Select>
                                        </div>
                                    </Col>
                                    <Col md={5} className="mb-4">
                                        <div>
                                            <Form.Control
                                                type="text"
                                                minLength={6}
                                                placeholder="000000"
                                                name="nrcNumber"
                                                onChange={e => setNrcNumber(e)}
                                                isInvalid={this.state.emptyField.includes('nrcNumber')}
                                            />
                                            <Form.Control.Feedback type='invalid'>
                                                {'မှတ်ပုံတင်အမှတ်အားထည့်သွင်းပါ'}
                                            </Form.Control.Feedback>
                                        </div>
                                    </Col>
                                </Form.Group>
                                <Form.Group className="row">
                                    <Col md={6} className="mb-4">
                                        <Form.Label className='col-md-6'>ရာထူး / အလုပ်အကိုင် <strong className='mandatorytxt'>*</strong></Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="ရာထူး / အလုပ်အကိုင်အားထည့်သွင်းပါ"
                                            name="position"
                                            isInvalid={this.state.emptyField.includes('position')}
                                            onChange={e => this.setState({ position: e.target.value })} />
                                        <Form.Control.Feedback type='invalid'>
                                            {'ရာထူး / အလုပ်အကိုင်အားထည့်သွင်းပါ'}
                                        </Form.Control.Feedback>
                                    </Col>
                                    <Col md={6} className="mb-4">
                                        <Form.Label className='col-md-8'>စက်ရုံ (သို့) လုပ်ငန်း အမည် <strong className='mandatorytxt'>*</strong></Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="စက်ရုံ (သို့) လုပ်ငန်း အမည်အားထည့်သွင်းပါ"
                                            name="factoryname"
                                            isInvalid={this.state.emptyField.includes('factoryname')}
                                            onChange={e => this.setState({ factoryname: e.target.value })} />
                                        <Form.Control.Feedback type='invalid'>
                                            {'လုပ်ငန်း အမည်အားထည့်သွင်းပါ'}
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group>
                                <Form.Group className="row">
                                    <Form.Label>စက်ရုံ (သို့) လုပ်ငန်း တည်နေရာ <strong className='mandatorytxt'>*</strong></Form.Label>
                                    <Col md={6} className="mb-3">
                                        <div>
                                            <Form.Select value={this.state.stateVal} onChange={onMMStateSelectChanged}>
                                                {states}
                                            </Form.Select>
                                        </div>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <div>
                                            {this.state.stateCity !== null ? (
                                                <Form.Select value={this.state.stateCity} onChange={e => onMMStateChanged(e)}>
                                                    {this.state.mmState}
                                                </Form.Select>
                                            ) : (
                                                <Form.Select value={this.state.mmState} multiple={false} onChange={e => onMMStateChanged(e)}>
                                                    {this.state.mmState}
                                                </Form.Select>
                                            )}
                                        </div>
                                    </Col>
                                    <Col md={12} className='mb-4'>
                                        <div>
                                            <Form.Control
                                                type="text"
                                                placeholder="အမှတ် / လမ်း / ရပ်ကွက်"
                                                name="factoryaddress"
                                                isInvalid={this.state.emptyField.includes('factoryaddress')}
                                                onChange={e => setMMAddress(e)} />
                                            <Form.Control.Feedback type='invalid'>
                                                {'အမှတ် / လမ်း / ရပ်ကွက်အားထည့်သွင်းပါ'}
                                            </Form.Control.Feedback>
                                        </div>
                                    </Col>
                                </Form.Group>
                                <Form.Group className="row">
                                    <Col md={6} className='mb-4'>
                                        <Form.Label className='col-md-12'>ပြန်လည်ဆက်သွယ်ရမည့် Telegram ဖုန်းနံပါတ် <strong className='mandatorytxt'>*</strong></Form.Label>
                                        <InputGroup>
                                            <Form.Control
                                                type="tel"
                                                placeholder="Telegram နံပါတ်အားထည့်သွင်းပါ"
                                                name="telegramnum"
                                                isInvalid={this.state.emptyField.includes('telegramnum')}
                                                onChange={e => this.setState({ telegram: e.target.value })} />
                                            {/* <InputGroup.Text id="basic-addon1"><span className="input-group-addon" id="basic-addon1">
                                                    <Image className='inputgrouplogo' src={telegramlogo} alt={telegramlogo} />
                                                </span></InputGroup.Text> */}
                                            <InputGroup.Text id="basic-addon1">#</InputGroup.Text>
                                            <Form.Control.Feedback type='invalid'>
                                                {'Telegram နံပါတ်အားထည့်သွင်းပါ'}
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Col>
                                    <Col md={6} className='mb-4'>
                                        <Form.Label className='col-md-8'>အီးမေးလ် လိပ်စာ <strong className='optionaltxt'>(Optional)</strong></Form.Label>
                                        <InputGroup>
                                            <Form.Control type="email" placeholder="အီးမေးလ်လိပ်စာအားထည့်သွင်းပါ" name="email" onChange={e => this.setState({ email: e.target.value })} />
                                            <InputGroup.Text id="basic-addon1">@</InputGroup.Text>
                                        </InputGroup>
                                    </Col>
                                </Form.Group>
                                <Form.Group className="mb-4 row">
                                    <Col md={12}>
                                        <Form.Label>တိုင်ကြားလိုသည့် အကြောင်းအရာ <strong className='mandatorytxt'>*</strong></Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="အကြောင်းအရာအားထည့်သွင်းပါ"
                                            name="cmtitle"
                                            isInvalid={this.state.emptyField.includes('title')}
                                            onChange={e => this.setState({ cmtitle: e.target.value })} />
                                        <Form.Control.Feedback type='invalid'>
                                            {'အကြောင်းအရာအားထည့်သွင်းပါ'}
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group>
                                <Form.Group className="mb-4 row">
                                    <Col md={12}>
                                        <Form.Label>အမှုဖြစ်စဉ် <strong className='mandatorytxt'>*</strong></Form.Label>
                                        <Form.Control
                                            className="form-control"
                                            name="cmbody"
                                            isInvalid={this.state.emptyField.includes('description')}
                                            id="exampleFormControlTextarea1"
                                            rows="5"
                                            onChange={e => this.setState({ description: e.target.value })} as="textarea" />
                                        <Form.Control.Feedback type='invalid'>
                                            {'အမှုဖြစ်စဉ်အားထည့်သွင်းပါ'}
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group>
                                <Form.Group className={`row ${this.state.isMobile === false ? "mb-4" : "mb-2"}`}>
                                    <Col md={12} className="d-grid gap-2">
                                        <Button variant="primary" className="mt-3 btn-lg" size="lg" onClick={checkValidation}> Submit </Button>
                                        <DataModal show={this.state.showModal} onHide={() => this.setState({ showModal: false })} data={this.state} />
                                    </Col>
                                </Form.Group>
                            </Form>
                            <Alert variant="info" className={`container alertbody alertlocal rounded ${this.state.isMobile === false ? "shadow col-lg-8 my-5 p-5" : "mx-2.5 p-4 mb-4"}`}>
                                <Alert.Heading className='font-weight-bold'><span class="admonitionIcon_Ibzs"><svg viewBox="0 0 14 16"><path fill-rule="evenodd" d="M7 2.3c3.14 0 5.7 2.56 5.7 5.7s-2.56 5.7-5.7 5.7A5.71 5.71 0 0 1 1.3 8c0-3.14 2.56-5.7 5.7-5.7zM7 1C3.14 1 0 4.14 0 8s3.14 7 7 7 7-3.14 7-7-3.14-7-7-7zm1 3H6v5h2V4zm0 6H6v2h2v-2z"></path></svg></span>အသိပေးချက်</Alert.Heading>
                                <p className='alertdesc'>
                                    ဝန်ကြီးဌာနအနေဖြင့် တော်လှန်ရေးကာလအတွင်း ညှိနှိုင်းဖြေရှင်းဆောင်ရွက်ပေးနိုင်သည့် တိုင်ကြားမှုများကို အချိန်နှင့် တပြေးညီ ဖြေရှင်းဆောင်ရွက်ပေးလျက်ရှိပြီး အရေးယူမှုပြုလုပ်ရန်လိုအပ်သည့် အလုပ်သမားအခွင့်အရေးချိုးဖောက်မှုများအား စနစ်တကျ မှတ်တမ်းထားရှိ၍ ပြည်သူ့အစိုးရ တာဝန်ယူချိန်တွင် စီစစ်အရေးယူသွားမည်ဖြစ်ကြောင်း အသိပေးအပ်ပါသည်။
                                </p>
                            </Alert>
                            <Footer />
                        </Container>
                    ) : (
                        <LoadingSceen />
                    )
                }
            </>
        )
    }
}

function DataModal(props) {

    const submitData = () => {
        submitLocalData(props.data).then((res) => {
            console.log(res)
            if (res.code === 200) {
                localStorage.setItem("code", res.code);
                localStorage.setItem("message", res.message);
            } else {
                localStorage.setItem("code", res.code);
                localStorage.setItem("message", res.message);
            }
            window.location = '/success?localcomplaint';
        });
    }
    return (
        <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    အချက်အလက်များအားစစ်ဆေးပါ။
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group className="mb-4 row">
                    <Col md={4}>
                        <Form.Label className='mb-2'> <strong> အမည် </strong></Form.Label>
                    </Col>
                    <Col md={8}>
                        <Form.Label className='mb-2'> : {props.data.name} </Form.Label>
                    </Col>
                </Form.Group>
                <Form.Group className="mb-4 row">
                    <Col md={4}>
                        <Form.Label className='mb-2'> <strong> ကျား / မ </strong></Form.Label>
                    </Col>
                    <Col md={8}>
                        <Form.Label className='mb-2'> : {props.data.gender} </Form.Label>
                    </Col>
                </Form.Group>
                <Form.Group className="mb-4 row">
                    <Col md={4}>
                        <Form.Label className='mb-2'> <strong> မှတ်ပုံတင်အမှတ် </strong></Form.Label>
                    </Col>
                    <Col md={8}>
                        <Form.Label className='mb-2'> : {props.data.nrc} </Form.Label>
                    </Col>
                </Form.Group>
                <Form.Group className="mb-4 row">
                    <Col md={4}>
                        <Form.Label className='mb-2'> <strong> ရာထူး / အလုပ်အကိုင် </strong></Form.Label>
                    </Col>
                    <Col md={8}>
                        <Form.Label className='mb-2'> : {props.data.position} </Form.Label>
                    </Col>
                </Form.Group>
                <Form.Group className="mb-4 row">
                    <Col md={4}>
                        <Form.Label className='mb-2'> <strong> စက်ရုံ (သို့) လုပ်ငန်း အမည်</strong></Form.Label>
                    </Col>
                    <Col md={8}>
                        <Form.Label className='mb-2'> : {props.data.factoryname} </Form.Label>
                    </Col>
                </Form.Group>
                <Form.Group className="mb-4 row">
                    <Col md={4}>
                        <Form.Label className='mb-2'> <strong> စက်ရုံ (သို့) လုပ်ငန်း တည်နေရာ</strong></Form.Label>
                    </Col>
                    <Col md={8}>
                        <Form.Label className='mb-2'> : {props.data.address} </Form.Label>
                    </Col>
                </Form.Group>
                <Form.Group className="mb-4 row">
                    <Col md={4}>
                        <Form.Label className='mb-2'> <strong> Telegram နံပါတ် </strong></Form.Label>
                    </Col>
                    <Col md={8}>
                        <Form.Label className='mb-2'> : {props.data.telegram} </Form.Label>
                    </Col>
                </Form.Group>
                <Form.Group className="mb-4 row">
                    <Col md={4}>
                        <Form.Label className='mb-2'> <strong> အီးမေးလ်လိပ်စာ </strong></Form.Label>
                    </Col>
                    <Col md={8}>
                        <Form.Label className='mb-2'> : {props.data.email} </Form.Label>
                    </Col>
                </Form.Group>
                <Form.Group className="mb-4 row">
                    <Col md={4}>
                        <Form.Label className='mb-2'> <strong> တိုင်ကြားလိုသည့် အကြောင်းအရာ </strong></Form.Label>
                    </Col>
                    <Col md={8}>
                        <Form.Label className='mb-2'> : {props.data.cmtitle} </Form.Label>
                    </Col>
                </Form.Group>
                <Form.Group className="mb-4 row">
                    <Col md={4}>
                        <Form.Label className='mb-2'> <strong> အမှုဖြစ်စဉ် </strong></Form.Label>
                    </Col>
                    <Col md={8}>
                        <Form.Label className='mb-2'> : {props.data.description} </Form.Label>
                    </Col>
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.onHide}>Cancel</Button>
                <Button onClick={submitData}>Confirm</Button>
            </Modal.Footer>
        </Modal>
    );
}